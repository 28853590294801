import React from 'react';
import './index.scss'

interface InputProps {
  placeholder: string;
  onChangeText: (text: string) => void;
}

const SearchInput = (props: InputProps) => {
  const {
    placeholder,
    onChangeText
  } = props;

  return (
    <div className='search-input-container'>
      <input
        type="text"
        className='search-input'
        placeholder={placeholder}
        onChange={(e) => onChangeText(e.target.value)}
      />
    </div>
  );
};

export default SearchInput;
