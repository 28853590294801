
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { clearUserDetails, setUserDetails, updateUserClassRoom, updateUserDetails } from '../redux/slices/setUser/userSlice'
import {
  clearClassroomData,
  clearSelectedClassMetadata,
  setSelectedClassMetadata,
  clearActiveClassroom,
  setUpdateTodaysEvent,
  setUpdateEvent,
  setUpdateDocument,
  setUpdateCarriculam,
  setUpdateSubject,
  setActiveClassRoom,
  setUpdateNote,
  setLoadingNotes,
  setLoadingDocument,
  setLoadingSubject,
  setLoadingEvent,
  setLoadingCarriculam,
  setMetaData
} from "../redux/slices/setClassroom/classRoomSlice";
import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import { useSnackbar } from "../components/atoms/SnackbarProvider";
import { useMetadataContext } from "contextApi/MetadataContext";
import useSWR from 'swr';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from "./commonUtils";

export const useJoinRoom = () => {
  const dispatch = useDispatch();
  const getUpdateUser = useUpdateUser()
  const navigate = useNavigate();
  const swichClass = useSwichClass()
  const user = loadUserFromLocalStorage();

  const joinRoom = async (id: string, isPublic: boolean) => {
    const res = await fetchData(restAPIs.getClassRoom(id, { userId: user?._id }), navigate);
    if (res.status === 200 && res.data) {
      let temp_data = res.data;
      if (isPublic) {
        temp_data.membershipStatus = 'ACTIVE';
      }
      dispatch(updateUserClassRoom(temp_data))
      if (isPublic)
        swichClass(id, isPublic)
      else{
        getUpdateUser()
      }
    }
  };

  return joinRoom;
};

export const useClassMetadata = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getClassMetadata = async (id: string) => {
    dispatch(clearSelectedClassMetadata())
    const res = await fetchData(restAPIs.getClassMetadata(id), navigate)
    if (res.status === 200 && res.data) {
      dispatch(setSelectedClassMetadata(res.data))
    }
    else {
      console.error('error', res)
    }
  }

  return getClassMetadata;
};

export const useSwichClass = () => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { SelectedClassRoom } = useSelector((state: any) => state.classRoom) || {};
  const activeClassroom = loadActiveClassroomLocalStorage()
  const swichClass = async (id: string, skipMembershipCheck?: boolean) => {
    if (SelectedClassRoom?.membershipStatus == 'ACTIVE' || skipMembershipCheck) {
      if (activeClassroom?._id == id) {
        showSnackbar('Already in this class room', 'success')
      }
      else {
        dispatch(clearClassroomData())
        localStorage.removeItem('classroomData')
        navigate(`/classroomfetch/${id}/switch`)
      }
    }
  }

  return swichClass;
};

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const logOut = async () => {
    dispatch(clearUserDetails())
    dispatch(clearClassroomData())
    dispatch(clearActiveClassroom())
    navigate('/login')
  }

  return logOut;
};

export const performLogout = (dispatch: Function, navigate: Function) => {
  return async () => {
    dispatch(clearUserDetails());
    dispatch(clearClassroomData());
    dispatch(clearActiveClassroom());
    navigate('/login');
  };
};

export const useFetchClassRoomItems = () => {

  const navigate = useNavigate();

  const { setIsUpdateUserLoading } = useMetadataContext();
  var today = new Date();
  const date = new Date();
  date.setDate(date.getDate() + 1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  let temp_month = today.getMonth() + 1;
  var lastDay = new Date(today.getFullYear(), temp_month + 1, 0);
  var lastdayString = new Date(lastDay.getTime()).toISOString();

  let dayStart = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  let dayEnd = new Date(new Date().setHours(23, 59, 59, 999)).toISOString();

  var FirstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  var FirstDayString = new Date(FirstDay.getTime()).toISOString();
  const dispatch = useDispatch();
  const activeClassroom = loadActiveClassroomLocalStorage()
  const { setMetadata, setIsMetaDataLoading } = useMetadataContext();

  const fetchTodaysEvents = async (classRoomId: string | undefined) => {
    let params = {
      startTime: dayStart,
      endTime: dayEnd,
      offset: 0,
    };
    dispatch(setLoadingEvent())
    const res = await fetchData(restAPIs.getTodaysEvents(classRoomId, params), navigate)
    if (res?.status === 200 && res?.data)
      dispatch(setUpdateTodaysEvent(res?.data))
  }

  const fetchEvents = async (classRoomId: string | undefined) => {
    let params = {
      startTime: FirstDayString,
      endTime: lastdayString,
      offset: 0,
    };
    dispatch(setLoadingEvent())
    const res = await fetchData(restAPIs.getEvents(classRoomId, params), navigate)
    if (res?.status === 200 && res?.data)
      dispatch(setUpdateEvent(res?.data))
  }

  const fetchDocuments = async (classRoomId?: string | undefined, offset?: number, type?: string) => {
    let params = {
      id: classRoomId ?? activeClassroom?._id,
      type: type ?? 'classroom',
      offset: offset ?? 0,
    };
    dispatch(setLoadingDocument())
    const res = await fetchData(restAPIs.getDocuments(params), navigate)
    if (res?.status === 200 && res?.data)
      dispatch(setUpdateDocument({ ...res?.data, offset: offset }))
  }

  const fetchCurriculum = async (classRoomId?: string) => {
    dispatch(setLoadingCarriculam())
    let params = {
      classRoomId: classRoomId,
    };
    const res = await fetchData(restAPIs.getCurriculum(params), navigate)
    if (res?.status === 200 && res?.data)
      dispatch(setUpdateCarriculam(res?.data))
  }

  const fetchSubject = async (classRoomId?: string, page?: number) => {
    dispatch(setLoadingSubject())
    const res = await fetchData(restAPIs.getSubjects(classRoomId), navigate)
    if (res?.status === 200 && res?.data)
      dispatch(setUpdateSubject({ ...res?.data, offset: page }))
  }

  const fetchClassroom = async (classRoomId?: string) => {
    if(!classRoomId) return
    const res = await fetchData(restAPIs.getActiveClassDetails(classRoomId), navigate)
    if (res?.status === 200 && res?.data)
      dispatch(setActiveClassRoom(res?.data))
  }

  const fetchNotes = async (classRoomId?: string, page?: number) => {
    let params = {
      id: classRoomId ?? activeClassroom?._id,
      type: 'classroom',
      offset: page ?? 0,
    };
    dispatch(setLoadingNotes())
    const res = await fetchData(restAPIs.getNotes(params), navigate)
    if (res?.status === 200 && res?.data)
      dispatch(setUpdateNote({ ...res?.data, offset: page }))
  }

  const fetchMetaData = async () => {
    setIsMetaDataLoading(true)
    const res = await fetchData(restAPIs.getMetaData(activeClassroom?._id), navigate)
    setIsMetaDataLoading(false)
    if (res.status === 200 && res.data) {
      setMetadata(res.data);
    }
  }

  return { fetchTodaysEvents, fetchEvents, fetchDocuments, fetchCurriculum, fetchSubject, fetchClassroom, fetchNotes, fetchMetaData };
};

export const useUpdateUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsUpdateUserLoading } = useMetadataContext();

  const getUpdateUser = async () => {
    setIsUpdateUserLoading(true)
    const res = await fetchData(restAPIs.getUserUpdate('get'), navigate)
    setIsUpdateUserLoading(false)
    if (res.status === 200 && res.data) {
      dispatch(updateUserDetails(res.data?.data))
    }
    else {
      console.error('error', res)
    }
  }

  return getUpdateUser;
};

export function useCustomSWR(request: any) {
  const navigate = useNavigate();

  const { data, error, mutate } = useSWR(
    request.endpoint,
    () => fetchData(request, navigate),
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}