import React, { useEffect, useState } from 'react';
import './index.scss'
import { useNavigate, useParams } from 'react-router-dom';
import GridContainer from 'components/molecules/GridContainer';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { Avatar, CircularProgress } from '@mui/material';
import { colors } from 'assets/styles/colors';
import { getTime } from 'utils/commonUtils';
import { ClassRoomListCard } from 'components/atoms/ClassRoomCard';
import ClassRoomDetailModal from 'components/atoms/ClassRoomDetailModal';
import { useClassMetadata } from 'utils/customHooks';

const UserProfile: React.FC = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const getClassMetadata = useClassMetadata();
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [selectedItem, setItem] = useState<any>(null);

    const dataFetch = async () => {
        let params = {
            peerUserId: id,
        };
        setLoading(true)
        const res = await fetchData(restAPIs.getUserPeersData(params), navigate)
        setLoading(false)
        if (res.status === 200 && res.data) {
            setData(res.data)
        }
    }

    useEffect(() => {
        dataFetch()
    }, []);

    const handleDetailClose = () => {
        setItem(null)
    };

    const getClassDetails = (item: any) => {
        setItem(item)
        getClassMetadata(item._id)
    }

    const peerUser = data?.peerUser;
    const commonClassRooms=data?.commonClassRooms
    return (
        <GridContainer
            pageTitle={'\t'}
            backButton
            leftGridPartition={3 / 4}
        >
            {loading ? <CircularProgress size={15} sx={{ color: colors?.primary }} /> :
                <div className='__user_profile_container'>
                    <div className='user_profile'>
                        <Avatar
                            src={peerUser?.profilePic}
                            sx={{ bgcolor: '#575757', width: '80px', height: '80px', fontSize: '14px' }}
                            aria-label="recipe"
                        >
                            {peerUser?.firstName?.slice(0, 1)}
                            {peerUser?.lastName?.slice(0, 1)}
                        </Avatar>
                    </div>
                    <div className='user_name'>{peerUser?.firstName} {peerUser?.lastName}</div>
                    <div className='user_stats'>
                        <div>
                            <div className='title'>Classrooms</div>
                            <div className='value'>{data?.peerUserClassRoomCount}</div>
                        </div>
                        <div className='divider'></div>
                        <div>
                            <div className='title'>Last Activity</div>
                            <div className='value'>{getTime(data?.lastActivity)}</div>
                        </div>
                    </div>
                    <div className='user_common_classrooms'>
                    <ClassRoomListCard from='PeerUserProfile' data={commonClassRooms} onClick={getClassDetails} />
                    <ClassRoomDetailModal item={selectedItem} onClose={handleDetailClose} />
                    </div>
                </div>}
        </GridContainer>
    );
}

export default UserProfile;