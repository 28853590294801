import React, { useState } from 'react';
import { LikeCommentSec } from 'pages/Feed/styles';
import { colors } from 'assets/styles/colors';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useSnackbar } from '../SnackbarProvider';
import CommentlModal from '../../molecules/CommentModal';
import { useNavigate } from 'react-router-dom';
import { loadUserFromLocalStorage } from 'utils/commonUtils';

interface HelloWorldProps {
    item: any;
    from:string;
    disable?:boolean|undefined
}

const PostFooter: React.FC<HelloWorldProps> = ({ item, from,disable }) => {
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const user = loadUserFromLocalStorage();
    const [downVotes, setDownVotes] = useState(item?.downVotes);
    const [upVotes, setUpVotes] = useState(item?.upVotes);
    const [commentCount, setCount] = useState(item?.commentCount);
    const [commentVisible,setCommentVisible]=useState<string|null>(null)
    const [selected, setSelected ]=useState<any>(null)

    const getUpvoteCount = (upVotes: any) =>
        upVotes?.length === 0 ||
            (upVotes?.length === 1 && upVotes?.includes(user?._id))
            ? 'Vote'
            : `${upVotes.length} Votes`;

    const getUpColor = (upVotes: any) =>
        upVotes.includes(user?._id) ? colors.primary : colors.secondary;

    const checkUpVotesUser = () => upVotes?.includes(user?._id);
    const checkDownVoteUser = () => downVotes?.includes(user?._id);

    const updateVote = (id: string, type: string) => {
        const upArray = upVotes;
        const downArray = downVotes;
        const newId = user?._id;

        if (type === 'downvote') {
            if (!downArray.includes(newId)) {
                downArray.push(newId);
            } else {
                downArray.splice(downArray.indexOf(newId), 1);
            }

            while (upArray.indexOf(newId) !== -1) {
                upArray.splice(upArray.indexOf(newId), 1);
            }

            setDownVotes([...downArray]);
            setUpVotes([...upArray]);
        } else {
            if (!upArray.includes(newId)) {
                upArray.push(newId);
            } else {
                upArray.splice(upArray.indexOf(newId), 1);
            }

            while (downArray.indexOf(newId) !== -1) {
                downArray.splice(downArray.indexOf(newId), 1);
            }

            setDownVotes([...downArray]);
            setUpVotes([...upArray]);
        }
    };

    const onVote = async (id: string, type: string) => {
        updateVote(id, type);
        const res = await fetchData(restAPIs.getPostVote(id, type),navigate)
        if (res.data && res.status === 200) {
        } else {
            updateVote(id, type);
            showSnackbar(res?.data?.message ?? 'Something went wrong. Please try again!!', 'error')
        }
    };

    const onClose=()=>{
        setSelected(null)
    }

    return (
        <div style={{paddingLeft: '45px'}}>
        <LikeCommentSec>
            <div className='__like'>
                {checkUpVotesUser() ? 
                    <i className="fi fi-sr-up" onClick={() => onVote(item._id, 'upvote')} style={{ color: getUpColor(upVotes) }}></i>
                    :
                    <i className="fi fi-rr-up" onClick={() => onVote(item._id, 'upvote')}></i>
                    }
                    
                <span  onClick={() => onVote(item._id, 'upvote')} style={{ color: getUpColor(upVotes) }}>{getUpvoteCount(upVotes)}</span>
                {checkDownVoteUser() ? 
                    <i className="fi fi-sr-down" onClick={() => onVote(item._id, 'downvote')} style={{ color: getUpColor(downVotes) }} ></i>
                    :
                    <i className="fi fi-rr-down" onClick={() => onVote(item._id, 'downvote')}></i>
                    }
                {downVotes?.length > 0 &&
                    <span style={{ color: getUpColor(downVotes) }}>{downVotes?.length}</span>}
            </div>
            <div onClick={()=>!disable&&setSelected(selected?null:item)} className='__like'>
                <i className="fi fi-rr-comment-dots"></i>
                <span style={{ cursor: 'pointer' }}>{commentCount} Comments</span>
            </div>
        </LikeCommentSec>
        <CommentlModal commentCount={commentCount} setCount={setCount} item={selected} onClose={onClose} />
        </div>
    );
};

export default PostFooter;
