import React, { useState } from 'react';
import './index.scss'
import GridContainer from 'components/molecules/GridContainer';
import { Box, Switch, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { SET_PRIVACY_PRIVATE_DESC, SET_PRIVACY_PUBLIC_DESC, SET_PRIVACY_TOOGLE_INFO, SET_PRIVACY_TOOGLE_INFO_TITLE, SET_PRIVACY_IMAGE_PICKER, SET_PRIVACY_CLASSROOM_DESC } from 'utils/constants';
import { SlCamera } from "react-icons/sl";
import { WriteClassRoomDesc } from 'pages/Feed/styles';
import PrimaryButton from 'components/atoms/CustomButtons';
import CustomImagePicker from 'components/atoms/CustomImagePicker';
import Axios from 'axios';
import { S3UPLOAD } from 'utils/restAPIs';
import { fetchData } from "utils/fetch";
import { restAPIs } from 'utils/restAPIs';
import { useNavigate } from 'react-router-dom';
import { useUpdateUser } from 'utils/customHooks';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';

interface DescChange {
    target: {
        value: string;
    };
}
const SetPrivacy: React.FC = () => {

    const navigate = useNavigate()
    const getUpdateUser = useUpdateUser()
    const { showSnackbar } = useSnackbar();
    const activeClassroom = loadActiveClassroomLocalStorage()
    const user = loadUserFromLocalStorage();
    const [isPrivate, setPrivacy] = useState<any>(false);
    const [allowUpdates, setAllowUpdates] = useState(true);
    const [desc, setDesc] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [logoImage, setLogoImage] = useState<File | null>(null);
    const [coverImage, setCoverImage] = useState<File | null>(null);
    const [percentage, setPercentage] = useState(0);

    const handleChange = (value: string) => {
        setPrivacy(value === 'private');
    };

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowUpdates(event.target.checked);
    };

    const handlePostChange = (event: DescChange) => {
        setDesc(event.target.value);
    }

    const uploadLogo = async () => {
        let logoUri: { logo: string; }[]=[]
        if (logoImage) {
            setLoading(true)
            setPercentage(1)
            const config = {
                headers: {
                    'Authorization': user?.token,
                },
            };
            let formData = new FormData();
            formData.append('file', logoImage,logoImage.name);
            const response = await Axios.post(
                S3UPLOAD + `classroom/${activeClassroom?._id}/logo/${logoImage.name}`,
                formData,
                config,
            );
            if (response.status == 200) {
                 logoUri=[{logo:`classroom/${activeClassroom?._id}/logo/${logoImage.name}`}]
            }
            else{
                showSnackbar('Something Went wrong on uploading logo.', 'error')
            }
            uploadCoverImage(logoUri)
        }
        else {
            uploadCoverImage(logoUri)
        }
    }

    const uploadCoverImage = async (logoUri: any[]) => {
        let cloudUri = [...logoUri]
        if (coverImage) {
            setLoading(true)
            setPercentage(2)
            const config = {
                headers: {
                    'Authorization': user?.token,
                },
            };
            let formData = new FormData();
            formData.append('file', coverImage,coverImage.name);
            const response = await Axios.post(
                S3UPLOAD + `classroom/${activeClassroom?._id}/coverImage/${coverImage.name}`,
                formData,
                config,
            );
            if (response?.status == 200) {
                 cloudUri=[...cloudUri,{coverImage:`classroom/${activeClassroom?._id}/coverImage/${coverImage.name}`}]
            }
            else{
                showSnackbar('Something Went wrong on uploading cover image.', 'error')
            }
            onFinsish(cloudUri)
        }
        else {
            onFinsish(cloudUri)
        }
    }

    const onFinsish = async(cloudUri:any) => {
        setPercentage(3)
        let params = {
            logo: cloudUri?.[0]?.logo??null,
            cover: cloudUri?.[cloudUri?.length-1]?.coverImage??null,
            about: desc,
            public: !isPrivate,
            getUpdatesFromTemplates: allowUpdates,
          };
          const res =await fetchData(restAPIs.getClassRoomPrivacy(activeClassroom?._id,params),navigate)
          setPercentage(4)
          if(res?.status===200){
            await getUpdateUser()
            setLoading(false)
            navigate('/feed', { replace: true })
          }
          else{
            showSnackbar('Something Went wrong. Please try again later', 'error')
            setLoading(false)
          }
    }

    const onSubmit = async () => {
        uploadLogo()
    }

    return (
        <GridContainer
            pageTitle={'Select Privacy Type *'}
            leftGridPartition={3 / 4}
        >
            <div className="__set_privacy_container">
                <div className='radio_container'>
                    <FormControl className='__form' fullWidth component="fieldset">
                        <RadioGroup
                            row
                            aria-label="privacy"
                            name="privacy"
                            value={isPrivate ? 'private' : 'public'}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                className={`__box ${isPrivate ? 'selected' : ''}`}
                                alignItems="center"
                                justifyContent="center"
                                marginRight={2}
                                onClick={() => handleChange('public')}
                            >
                                <div className="box_content">
                                    <h1>Public</h1>
                                    <FormControlLabel
                                        value="public"
                                        control={<Radio />}
                                        label=""
                                    />
                                </div>
                                <div className='__body'>{SET_PRIVACY_PUBLIC_DESC}</div>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                className={`__box ${!isPrivate ? 'selected' : ''}`}
                                alignItems="center"
                                justifyContent="center"
                                onClick={() => handleChange('private')}
                            >
                                <div className="box_content">
                                    <h1>Private</h1>
                                    <FormControlLabel
                                        value="private"
                                        control={<Radio />}
                                        label=""
                                    />
                                </div>
                                <div className='__body'>{SET_PRIVACY_PRIVATE_DESC}</div>
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className='__allow_update_container'>
                    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ paddingTop: '15px', paddingBottom: '15px' }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <h1>{SET_PRIVACY_TOOGLE_INFO_TITLE}</h1>
                            <Switch
                                checked={allowUpdates}
                                onChange={handleToggle}
                                color="primary"
                            />
                        </Box>
                        <div className='__body'>
                            {SET_PRIVACY_TOOGLE_INFO}
                        </div>
                    </Box>
                </div>
                <div>
                    {SET_PRIVACY_IMAGE_PICKER?.map((item, index) => {
                        let title = index == 0 ? logoImage?.name ?? item.data.title : coverImage?.name ?? item.data.title
                        return (
                            <div className="__image_picker">
                                <div className='__image_picker_container'>
                                    <h1>{item.title}</h1>
                                    <div className='__body'>(optional)</div>
                                </div>
                                <div className='__image_picker_box'>
                                    <div className='__image_picker_content'>
                                        <div className='title'>{title}</div>
                                        <div className='__sub__body'>{item.data.desc}</div>
                                    </div>
                                    <CustomImagePicker id={`picker-${index}`} setSelectedImage={index == 0 ? setLogoImage : setCoverImage}>
                                        <div><SlCamera size={20} /></div>
                                    </CustomImagePicker>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <div className="__image_picker">
                        <div className='__image_picker_container'>
                            <h1>About Classroom</h1>
                            <div className='__body'>(optional)</div>
                        </div>
                        <WriteClassRoomDesc marginBottom={'0px'}>
                            <textarea autoFocus value={desc} onChange={handlePostChange} placeholder={SET_PRIVACY_CLASSROOM_DESC} className='post-text-area'></textarea>
                        </WriteClassRoomDesc>
                    </div>
                </div>
                <div className="__footer">
                    <PrimaryButton
                        onClick={onSubmit}
                        progress={percentage/4*100}
                        fullWidth={false}
                        disabled={false}
                        isLoading={isLoading}>
                        Finish
                    </PrimaryButton>
                </div>
            </div>
        </GridContainer>
    );
};

export default SetPrivacy;
