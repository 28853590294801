import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '../redux/store/store';
import { useDispatch } from 'react-redux';
import { clearUserDetails } from '../redux/slices/setUser/userSlice';
import { performLogout } from './customHooks';
import { useNavigate } from 'react-router-dom';



interface Request {
  method: string;
  endpoint: string;
  body?: Record<string, any>;
}

type NavigateFunction = (path: string) => void;
export const fetchData = async (request: Request, navigate?: NavigateFunction): Promise<any> => {
  const { method, endpoint, body } = request;
  let sessiontoken = localStorage.getItem('sessiontoken');
  const requestModel: AxiosRequestConfig = {
    method,
    url: endpoint,
    data: body
  };
  if (sessiontoken)
    requestModel.headers = {
      Authorization: sessiontoken
    };
  try {
    const response: AxiosResponse<any> = await axios.request(requestModel);

    return response.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      localStorage.clear();
      navigate?.('/login/reset'); // Navigate to the login page
    }
    return {
      status: e.response?.status,
      data: e.response?.data
    }
  }
};