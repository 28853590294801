import './index.scss'
import ModalView from '../../atoms/ModalView';
import { SingleFeed } from 'pages/Feed/styles';
import UserCard from '../../atoms/UserCard';
import PostImages from '../../atoms/PostImages';
import PostDoc from '../../atoms/PostDoc';
import PostUrls from '../../atoms/PostUrls';
import PostFooter from '../../atoms/PostFooter';
import PostComment from '../../atoms/PostComment';
import CommentList from '../../atoms/CommentList';
import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useSnackbar } from '../../atoms/SnackbarProvider';
import { Tooltip } from '@mui/material';
import { checkType, getTypeString, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

interface ChildProps {
    item: any;
    onClose?: () => void
    setCount?: React.Dispatch<React.SetStateAction<any>>;
    commentCount?: any;
    fromPostDetails?: boolean
}

const CommentlModal: React.FC<ChildProps> = ({ item, onClose, setCount, commentCount, fromPostDetails = false }) => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const user = loadUserFromLocalStorage();
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const activeClassroom = loadActiveClassroomLocalStorage()
    const subjects = classroomData?.subjectList
    const containerRef = useRef<HTMLDivElement>(null);
    const [isLoding, setLoading] = useState(false);
    const [comments, setComments] = useState<any>([])
    const [isLastPage, setLastPage] = useState(true)
    const [offset, setOffset] = useState<number>(0)
    const [editComments, setEditComments] = useState<any>(null)
    const [editIndex, setEditIndex] = useState<any>(null)
    const [selectedIndex, setSelectedIndex] = useState<any>(null);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        if (!fromPostDetails) {
            const timer = setTimeout(scrollToBottom, 100);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [item]);

    const fetchComments = async () => {
        setLoading(true)
        let params = {
            offset: offset,
        };
        const res = await fetchData(restAPIs.getComments(item?._id, params),navigate)
        setLoading(false)
        if (res.status === 200 && res.data) {
            if (offset == 0) {
                setTimeout(() => {
                    scrollToBottom()
                }, 100);
            }
            setComments(comments.concat(res.data.comments))
            setLastPage(res.data.isLastPage)
            setOffset(offset + 1)
        }
    }

    useEffect(() => {
        setOffset(0)
        setComments([])
        if (item) {
            fetchComments()
        }
    }, [item]);

    if (!item) {
        onClose?.()
        if (editComments) {
            setEditComments(null)
            setEditIndex(null)
        }
        return null
    }

    const onEditSend = async (comment: string) => {
        setLoading(true)
        setLoading(true)
        let params = {
            classRoomId: activeClassroom && activeClassroom._id,
            postId: item?._id,
            userId: user && user._id,
            comment: comment,
        };
        const res = await fetchData(restAPIs.getupdateComments(editComments?._id, params),navigate)
        setSelectedIndex(null)
        setEditComments(null)
        setEditIndex(null)
        setLoading(false)
        if (res?.data && res?.status === 200) {
            let temp = [...comments]
            temp[editIndex].comment = comment
            showSnackbar('Comment updated successfully', 'success')
        }
        else {
            showSnackbar('something went wrong !', 'error')
        }
    }

    const onSend = async (comment: string) => {
        if (editComments) {
            onEditSend(comment)
        }
        else {
            setLoading(true)
            setLoading(true)
            let params = {
                classRoomId: activeClassroom && activeClassroom._id,
                postId: item?._id,
                userId: user && user._id,
                comment: comment,
            };
            const res = await fetchData(restAPIs.getAddComments(params),navigate)
            setSelectedIndex(null)
            setLoading(false)
            if (res?.data && res?.status === 200) {
                setCount?.(commentCount + 1)
                let temp = [res.data, ...comments]
                setComments(temp)
                showSnackbar('Comment added successfully', 'success')
            }
            else {
                showSnackbar('something went wrong !', 'error')
            }
        }
    }

    const onEdit = async (item: any, index: number) => {
        setEditComments(item)
        setEditIndex(index)
    }

    const onDelete = async (item: any, index: number) => {
        setLoading(true)
        const res = await fetchData(restAPIs.getDeleteComments(item?._id),navigate)
        setSelectedIndex(null)
        setLoading(false)
        setEditComments(null)
        setEditIndex(null)
        if (res?.data && res?.status === 200) {
            let temp = [...comments]
            temp.splice(index, 1);
            setComments(temp)
            setCount?.(commentCount - 1)
            showSnackbar('Comment deleted successfully', 'success')
        }
        else {
            showSnackbar('something went wrong !', 'error')
        }
    }

    const getSubjectName = (details: any) => {
        let name = '';
        let subId = details?.subjectId ?? '';
        subjects?.length > 0 &&
            subjects.map((sub: any) => {
                if (sub._id == subId) {
                    name = sub.name;
                }
            });
        return name;
    };

    const renderModal = () => (
        <div className="comment-wrapper">
            <div ref={containerRef} className='comment-container'>
                <SingleFeed>
                    <UserCard getType={getTypeString(item?.type)} item={item} actionEnabled={false} />
                    {item.type === 'NOTE' ||
                        (item.type === 'DOCUMENT' && getSubjectName(item)?.length > 0) ? (
                        <Tooltip title={getSubjectName(item)}>
                            <div className='subject-tab'>{getSubjectName(item)}</div>
                        </Tooltip>) : null}
                    {checkType(item.type) && (
                        <p className='feed-subject-title'>
                            {_.capitalize(item.title)}
                        </p>
                    )}
                    <p style={{ paddingLeft: '45px' }} className='content-wrapper'>{item?.content}</p>
                    <div style={{ paddingLeft: '45px' }}>
                        <PostImages item={item.documents} />
                        <PostDoc item={item.documents} />
                        <PostUrls item={item.externalInfo} />
                    </div>
                    <PostFooter disable={true} item={item} from={''} />
                </SingleFeed>
                <div style={{ padding:fromPostDetails?'15px':0 }}>
                <CommentList
                    comments={comments}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    isLastPage={isLastPage}
                    fetchComments={fetchComments}
                    isLoding={isLoding}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    commentCount={commentCount} />
                    </div>
            </div>
            <div style={{ padding:fromPostDetails?'15px':0 }}>
                <PostComment setEditComments={setEditComments} editComments={editComments} post={item} onSend={onSend} user={user} />
            </div>
        </div>
    )

    return (
        fromPostDetails ?
            renderModal() :
            <ModalView
                open={item !== null}
                modalStyle={{ padding: 0 }}
                title={`${item?.userId?.firstName}'s Post`}
                handleClose={onClose}>
                <div className="create-comment-modal">
                    {renderModal()}
                </div>
            </ModalView>
    );
};

export default CommentlModal;

