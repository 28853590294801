import React, { FC, useState, useEffect } from 'react';
import { Avatar, Grid, Menu, MenuItem, Typography } from '@mui/material';
import LeftGrid from '../atoms/LeftGrid';
import RightGrid from '../atoms/RightGrid';
import '../../assets/styles/globalStyles.scss'
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import { useLogout } from 'utils/customHooks';
import LogOutModal from '../../components/atoms/LogOutModal';
import { useNavigate } from 'react-router-dom';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { FaChevronLeft } from 'react-icons/fa6';
import { loadUserFromLocalStorage } from 'utils/commonUtils';



interface ChildrenProps {
    children?: React.ReactNode;
    right?: React.ReactNode;
    leftGridPartition?: any;
    overflowY?: 'auto' | 'hidden' | 'scroll' | 'visible';
    rightOverflowY?: 'auto' | 'hidden' | 'scroll' | 'visible';
    pageTitle?: any
    loadMore?: () => void;
    page?: number,
    loading?: boolean;
    data?: any;
    onBack?: () => void;
    backButton?: boolean;
    optionsVisible?:boolean
    onEdit?:()=>void;
    onDelete?:()=>void;
}

const GridContainer: FC<ChildrenProps> = ({ children,onEdit,onDelete, backButton,optionsVisible=false, right, overflowY, leftGridPartition, pageTitle, loadMore, loading, page, rightOverflowY, data, onBack }) => {

    const { setToogleDrawer, toogleDrawer } = useMetadataContext();
    const user = loadUserFromLocalStorage();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const logout = useLogout()
    const [openLogout, setOpenLogout] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const settingsOpen = Boolean(anchorEl);
    const [anchorOptionEl, setAnchorOptionEl] = React.useState<null | HTMLElement>(null);
    const optionsOpen = Boolean(anchorOptionEl);
    const [activeItem, setActiveItem] = useState('Home');
    const navigate = useNavigate();

    const onClose = (value?: boolean) => {
        setToogleDrawer(value ?? !toogleDrawer)
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOptionClick = (event: any) => {
        setAnchorOptionEl(event.currentTarget);
    };
    const handleSettingsClose = () => {
        setAnchorEl(null);
        setAnchorOptionEl(null);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (window.innerWidth >= 900 && toogleDrawer) {
                setToogleDrawer(false)
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCloseLogOut = () => setOpenLogout(false);

    const handleOpenLogout = () => {
        handleSettingsClose()
        logout()
    }

    const handleSettings = () => {
        handleSettingsClose()
        setActiveItem('')
        setToogleDrawer(false);
        navigate('/settings');
    }

    const onEditItem=()=>{
        onEdit?.()
        handleSettingsClose()
    }
    const onDeleteItem=()=>{
        onDelete?.()
        handleSettingsClose()
    }

    return (
        <Grid sm={12} xs={12} md={9.6} lg={9.6} item >

            {pageTitle &&
                <div className='page-header'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {backButton ? <FaChevronLeft 
                        style={{ cursor: 'pointer', color: '#707070',paddingTop:'5px',paddingBottom:'5px',paddingRight:'5px'}}
                        onClick={() => navigate(-1)} size={'16px'} /> :
                            <div onClick={() => onClose(true)} className='burger-menu'>
                                {toogleDrawer ? <Close /> : <MenuIcon />}
                            </div>}
                        <Typography variant='h1' fontWeight={600} fontSize={17}>{pageTitle}</Typography>
                    </div>
                    <div className='option-container'>
                        <div>
                            <Avatar sx={{ bgcolor: '#575757', width: 34, height: 34, fontSize: '14px' }} aria-label="recipe" onClick={handleClick}>
                                {user?.firstName?.slice(0, 1)}{user?.lastName?.slice(0, 1)}
                            </Avatar>
                        </div>
                        {optionsVisible&&
                        <div onClick={handleOptionClick}>
                            <i className="fi fi-br-menu-dots-vertical"></i>
                        </div>}
                    </div>
                </div>}
            <Grid container spacing={0}>
                <LeftGrid loadMore={loadMore} data={data} loading={loading} page={page} leftGridPartition={leftGridPartition} overflowY={overflowY}>
                    {children}</LeftGrid>
                <RightGrid rightGridPartition={leftGridPartition ? 1 - leftGridPartition : null} overflowY={rightOverflowY}>{right}</RightGrid>
            </Grid>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={settingsOpen}
                onClose={handleSettingsClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    className: '__menu_com',
                    autoFocusItem: false,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        width: '10vw',
                        minWidth: '160px',
                        mt: 4,
                        mr: 4,
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        borderRadius: '13px'
                    },
                }}
            >
                <MenuItem className='menu-item' onClick={handleSettings}>
                    Account Settings
                </MenuItem>
                <MenuItem className='menu-item' onClick={() => setOpenLogout(true)}>
                    Logout</MenuItem>
            </Menu>

            <Menu
                id="basic-menu"
                anchorEl={anchorOptionEl}
                open={optionsOpen}
                onClose={handleSettingsClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    className: '__menu_com',
                    autoFocusItem: false,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        width: '5vw',
                        minWidth: '100px',
                        mt: 4,
                        mr: 4,
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        borderRadius: '13px'
                    },
                }}
            >
                <MenuItem className='menu-item' onClick={onEditItem}>
                    edit
                </MenuItem>
                <MenuItem className='menu-item' onClick={onDeleteItem}>
                    Delete</MenuItem>
            </Menu>

            <LogOutModal
                open={openLogout}
                modalStyle={{ padding: '0px' }}
                handleClose={handleCloseLogOut}
                handleOpen={handleOpenLogout}
            />
        </Grid>
    );
};

export default GridContainer;