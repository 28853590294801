import React, { useEffect, useState } from 'react';
import ModalView from '../ModalView';
import { IoIosClose } from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaRegFileVideo } from "react-icons/fa";
import { FaRegFileWord } from "react-icons/fa";
import { AiOutlineFilePdf } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import './index.scss'
import { useSelector } from 'react-redux';
import { colors } from 'assets/styles/colors';
import { getFileType, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import PrimaryButton from '../CustomButtons';
import { fetchData } from "utils/fetch";
import { HOST, restAPIs } from "utils/restAPIs"
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { useSnackbar } from '../SnackbarProvider';
import { Select, MenuItem, Typography } from '@mui/material';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { NOTE_TITLE,DOCUMENT_TITLE } from 'utils/constants';
import CreateSubjectModal from '../CreateSubjectModal';

interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    createType?: string;
    editItem?: any;
    addFile?: boolean;
    detailRefresh?:()=>void
}

interface FileItem {
    file: File;
    type: string;
}

const CreateNoteModal: React.FC<Props> = ({ open,detailRefresh, handleClose, modalStyle, createType, editItem, addFile }) => {

    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar();
    const { setToogleDrawer } = useMetadataContext();
    const { fetchNotes, fetchDocuments, fetchMetaData } = useFetchClassRoomItems()
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const activeClassroom = loadActiveClassroomLocalStorage()
    const user = loadUserFromLocalStorage();
    const subjects = classroomData?.subjectList
    const [name, setName] = useState('');
    const [selectedSubject, setSelectedSubject] = useState<any>(createType == 'Document' ? "" : subjects?.[0]);
    const [files, setFiles] = useState<FileItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [fileError, setFileError] = useState<any>(null);
    const [percentage, setPercentage] = useState(0);
    const [openSubjectModal, setOpenSubjectModal] = useState<string>('');

    useEffect(() => {
        if (editItem) {
            setName(editItem.title)
            setSelectedSubject(editItem.subjectId)
            if (!addFile) {
                setFiles(editItem.files)
            }
        }
    }, [editItem, addFile]);

    useEffect(() => {
        if (!editItem) {
            if (createType !== 'Document') {
                setSelectedSubject(subjects?.[0])
            }
            else {
                setSelectedSubject("")
            }
        }
    }, [createType]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const maxLength=createType !== 'Document'? NOTE_TITLE:DOCUMENT_TITLE;
        if(event.target?.value?.length <maxLength){
        setName(event.target.value);
        setError(null)
        }
        else{
            setError('Maximum length exceeds')
        }
    }

    const handleSubjectChange = (event: any) => {
        const selectedId = event.target.value;
        const selected = subjects?.find((subject: any) => subject?._id === selectedId);
        if (selected) {
            setSelectedSubject(selected);
        }
        else if (event.target.value == "none") {
            setSelectedSubject("");
        }
    };

    const onClose = () => {
        handleClose?.()
        setPercentage(0)
        setName('')
        setFileError('')
        setError(null)
        setSelectedSubject(editItem?.subjectId ? editItem.subjectId : createType == 'Document' ? '' : subjects?.[0])
        setFiles([])
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files);
            const fileItems = newFiles.map(file => {
                const fileType = getFileType(file);
                return { file, type: fileType };
            });
            setFiles((prevFiles) => [...prevFiles, ...fileItems]);
        }
    };

    const handleDeleteFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const onSuccess = () => {
        if (createType == 'Document') {
            fetchDocuments(activeClassroom?._id)
        }
        else {
            fetchNotes(activeClassroom?._id)
        }
        fetchMetaData()
        showSnackbar(`${createType} ${editItem ? 'updated' : 'created'}`, 'success')
        setIsLoading(false)
        onClose()
        if(detailRefresh){
            detailRefresh()
        }
        else{
        navigate(createType == 'Document' ? `/documents` : `/notes`)
        }
        setToogleDrawer(false)
    }

    const uploadNotes = async (data: any) => {
        setIsLoading(true)
        const token = user?.token;
        let totalNotes = files?.length;
        let uploadedNotes = 0;
        let currentNoteIndex = 0;
        async function uploadNextNote() {
            if (currentNoteIndex >= totalNotes) {
                onSuccess()
                return;
            }

            let document = files[currentNoteIndex];
            let timenow = new Date();
            const config = {
                headers: {
                    'Authorization': token,
                },
                onUploadProgress: function (progressEvent: any) {
                    const percentCompleted = (currentNoteIndex / files?.length +
                        progressEvent.loaded / progressEvent.total / files?.length) * 99;
                    setPercentage(percentCompleted);
                },
            };
            let formData = new FormData();
            formData.append('file', document?.file);
            formData.append('_id', document?.file?.lastModified?.toString() + document?.file?.name);
            formData.append('name', document?.file?.name);
            formData.append('size', document?.file?.size?.toString());
            formData.append('type', document?.file?.type);
            formData.append('createdAt', timenow.getTime().toString());

            try {
                const response = await Axios.post(
                    `${HOST}${createType == 'Document' ? 'document' : 'note'}` + `/${data?._id}/upload`,
                    formData,
                    config
                );
                if (response.status === 200 && response.data?.data) {
                    data.files = response?.data?.data?.files;
                    uploadedNotes++;
                    let overallProgress = Math.round((uploadedNotes / totalNotes) * 100);
                    currentNoteIndex++;
                    uploadNextNote();
                } else {
                    currentNoteIndex++;
                    uploadNextNote();
                }
            } catch (err) {
                currentNoteIndex++;
                uploadNextNote();
            }
        }
        uploadNextNote();
    }

    const onSubmit = async () => {
        if (name.trim()?.length > 0) {
            setIsLoading(true)
            let params = {
                classRoomId: activeClassroom?._id,
                subjectId: selectedSubject?._id,
                userId: user._id,
                title: name.trim(),
                addToBoard: editItem ? false : true,
            };
            const res = createType == 'Document' ?
                editItem ? await fetchData(restAPIs.getupdateDocuments(editItem._id, params),navigate) :
                    await fetchData(restAPIs.getAddDocument(params),navigate) :
                editItem ? await fetchData(restAPIs.getupdateNote(editItem._id, params),navigate) :
                    await fetchData(restAPIs.getAddNote(params),navigate)
            if (res.status == 200 && res.data) {
                if (files?.length > 0 && !editItem) {
                    uploadNotes(res.data)
                }
                else {
                    onSuccess()
                }
            }
            else {
                setIsLoading(false)
                showSnackbar('something went wrong !', 'error')
            }
        }
        else {
            setError('* Required')
        }
    }

    const onSubmitClick = () => {
        if (addFile) {
            if (files?.length > 0)
                uploadNotes(editItem)
            else
                setFileError('please upload atleast 1 file')
        }
        else {
            onSubmit()
        }
    }


    const RenderModal = () => (
        <div className="create-note-modal">
            <div className='__header'>
                <h4>{addFile ? 'Upload files' : editItem ? 'Edit' : 'Create'} {!addFile ? createType : ''}</h4>
                <button onClick={onClose}><IoIosClose /></button>
            </div>
            <div className="file-upload-container">
                <div className="input-group">
                    <label>Name</label>
                    <input disabled={addFile} value={name} type="text" onChange={handleNameChange} placeholder={`${createType} name`} />
                    <Typography sx={{ color: 'red' }} variant='subtitle2'>{error}</Typography>
                </div>

                <label>Select subject</label>
                {subjects?.length>0?
                <div className="__session__note">
                    <Select className='select' disabled={addFile} value={selectedSubject?._id ?? "none"} onChange={handleSubjectChange} >
                        {createType == 'Document' &&
                            <MenuItem value="none">None</MenuItem>}
                        {subjects?.map((subject: any) => (
                            <MenuItem key={subject?._id} value={subject?._id}>
                                {subject?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>:
                <div onClick={()=>setOpenSubjectModal('Subject')} className="subject_create_button">No Subject added yet. Click here to Create Subject</div>
                }
                {(!editItem || addFile) &&
                    <div className="file-upload-section">
                        <span>{files.length} files</span>
                        <label className="file-upload-button">
                            Add file
                            <input type="file" multiple onChange={handleFileChange} />
                        </label>
                    </div>}

                {(!editItem || addFile) && files.length > 0 && (
                    <ul className="file-list">
                        {files.map((fileItem: any, index) => {
                            return (
                                <li key={index}>
                                    {fileItem.type === 'Image' ? (
                                        <div className="file-preview">
                                            <img style={{ objectFit: 'contain' }} src={URL.createObjectURL(fileItem.file)} alt="Preview" />
                                        </div>
                                    ) : fileItem.type === 'PDF' ? (
                                        <AiOutlineFilePdf style={iconStyle} size={26} className="file-icon" />
                                    ) : fileItem.type === 'Document' ? (
                                        <IoDocumentTextOutline style={iconStyle} size={24} className="file-icon" />
                                    ) : fileItem.type === 'Video' ? (
                                        <FaRegFileVideo style={iconStyle} size={24} className="file-icon" />)
                                        : <FaRegFileWord style={iconStyle} size={24} className="file-icon" />}
                                    <div className="file-details">
                                        <div className="file-name">{fileItem.file?.name ?? fileItem?.name}</div>
                                        <MdDelete style={{ cursor: 'pointer' }} color={colors.secondary} size={20} onClick={() => handleDeleteFile(index)} />
                                    </div>
                                </li>
                            )
                        }
                        )}
                    </ul>
                )}
            </div>
            <Typography sx={{ color: 'red', marginRight: '25px' }} align='right' variant='subtitle2'>{fileError}</Typography>
            <div className='modal-footer-button'>
                <PrimaryButton
                    fullWidth={false}
                    disabled={createType !== 'Document'&&subjects?.length==0}
                    progress={(editItem && !addFile) ? null : percentage}
                    onClick={onSubmitClick}
                    isLoading={isLoading}>
                    {addFile ? 'Upload files' : editItem ? 'Update' : 'Create'} {!addFile ? createType : ''}
                </PrimaryButton>
            </div>
        </div>
    )

    return (
        <div>
        <ModalView open={open&&openSubjectModal!=='Subject'} handleClose={onClose} modalStyle={modalStyle}>
            {RenderModal()}
        </ModalView>
        <CreateSubjectModal
                    disableReroute={true}
                    open={openSubjectModal == 'Subject'}
                    modalStyle={{ padding: '0px' }}
                    createType={openSubjectModal}
                    handleClose={() => setOpenSubjectModal('')} />
        </div>
    )
};

export default CreateNoteModal;

const iconStyle = {
    width: '25px',
    marginRight: '10px'
}
