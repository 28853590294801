import { configureStore, Tuple } from '@reduxjs/toolkit';
import userSlice from '../slices/setUser/userSlice';
import classRoomSlice from '../slices/setClassroom/classRoomSlice';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';

const rootReducer = {
  user: userSlice,
  classRoom:classRoomSlice
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
