import React from 'react';
import { ProfileCard } from 'assets/styles/commonStyle';
import { Avatar } from '@mui/material';
import { TbDotsVertical } from 'react-icons/tb';
import { getTime } from 'utils/commonUtils';
import { useNavigate } from 'react-router-dom';

interface User {
  profilePic?: string;
  firstName?: string;
  lastName?: string;
  _id?: string;
}

interface Item {
  userId?: User;
  createdAt?: string;
}

interface CustomProfileCardProps {
  item: Item;
  actionEnabled?: boolean
  h1FontSize?: string
  pFontSize?: string
  width?:number
  height?:number
  children?:any;
  handleClick?: (event: React.MouseEvent<HTMLElement>,item:any,index:number|undefined) => void;
  selectedItem?:any;
  selectedIndex?:number
  getType?:any
}

const UserCard: React.FC<CustomProfileCardProps> = ({ item,selectedItem,selectedIndex,getType=null, actionEnabled = false, h1FontSize, pFontSize,width,height,children,handleClick }) => {

  const navigate = useNavigate();
  return (
    <ProfileCard h1FontSize={h1FontSize} pFontSize={pFontSize}>
      <Avatar
        src={item?.userId?.profilePic}
        sx={{ bgcolor: '#575757', width: width??34, height:height?? 34, fontSize: '14px' }}
        aria-label="recipe"
      >
        {item?.userId?.firstName?.slice(0, 1)}
        {item?.userId?.lastName?.slice(0, 1)}
      </Avatar>
      <div className="___label">
        <div className="name">
        <h1 onClick={() => navigate(`/user-profile/${item?.userId?._id}`)}>{item?.userId?.firstName} {item?.userId?.lastName}</h1>
        {getType&&<p>{getType}</p>}
        </div>
        {item?.createdAt&&
        <p>{getTime(item?.createdAt)}</p>}
      </div>
      {actionEnabled &&
        <button onClick={(e)=>handleClick?.(e,selectedItem,selectedIndex)}>
          <TbDotsVertical />
        </button>}
        {children&&actionEnabled&&children}
    </ProfileCard>
  );
};

export default UserCard;

