import React, { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { ProfileCard } from 'assets/styles/commonStyle';
import { Avatar, CircularProgress, IconButton, Popover, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CancelIcon from '@mui/icons-material/Cancel';
import { setActiveClassRoom } from '../../../redux/slices/setClassroom/classRoomSlice';
import { useCustomSWR, useFetchClassRoomItems, useUpdateUser } from 'utils/customHooks';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';


const ClassmatesListWrapper: React.FC<any> = ({ fromSettings }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const {
        fetchClassroom,
    } = useFetchClassRoomItems()
    const user = loadUserFromLocalStorage();
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const activeClassroom = loadActiveClassroomLocalStorage()
    const { isAdmin } = classroomData
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [isActionLoding, setActionLoading] = useState(false);
    const [list, setList] = useState<any>([])
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selected, setSelected] = useState<any>(null)
    const adimArray =
        activeClassroom?.owners?.length > 0 ? activeClassroom.owners : [];


    let array: any = activeClassroom?.members?.length > 0 && activeClassroom?.members;
    let temp_array: any = [];
    array.map((item: any) => {
        if (item.status == 'ACTIVE') {
            temp_array.push(item.userId);
        }
    });
    let params = temp_array;
    const { data: response, isLoading, isError, mutate } = useCustomSWR(restAPIs.getUserList(params));
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, item: any, index: number) => {
        setSelectedIndex(index)
        setSelected(item)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        fetchUsers();
    }, [response]);

    useEffect(() => {
        mutate();    
    }, [localStorage.getItem('activeClassroom')]);



    const fetchUsers = async () => {
        if (response?.data) {
            let userList = response?.data ? response.data : [];
            let sortedUserList = userList.sort((a: any, b: any) =>
                (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName),
            );
            let filteredPeople = fromSettings ? sortedUserList : sortedUserList.filter(
                (item: any) => item._id !== user?._id,
            );
            setList(filteredPeople);
        }
    }

    const makeAdmin = async (type?: string) => {
        let classRoomId = activeClassroom?._id;
        handleClose()
        setActionLoading(true)
        const response = await fetchData(restAPIs.getAddAdmin(classRoomId, selected?._id, type ?? ''), navigate);
        setActionLoading(false)
        if (response?.status === 200) {
            fetchClassroom(classRoomId)
        }
    };

    const onRemove = async () => {
        let classRoomId = activeClassroom?._id;
        handleClose()
        setActionLoading(true)
        let params = {
            studentId: selected?._id,
        };
        const res = await fetchData(restAPIs.getLeaveRoom(activeClassroom?._id, params, false), navigate)
        setActionLoading(false)
        if (res?.status === 200) {
            fetchClassroom(id)
            fetchClassroom(classRoomId)
        }
    };

    return (
        <div className="__full_list">
            {isLoading && <CircularProgress size={'20px'} color="primary" />}
            <div className='__header_list'>
                <div className='class-count'>{list?.length} {list?.length === 1 ? 'Member' : 'Members'}</div>
                {isAdmin &&
                    <IconButton style={{width:'40px',height:'40px'}} onClick={() => navigate('/add-classmates')}>
                        <PersonAddAltIcon />
                    </IconButton>
                }
            </div>
            {list?.map((item: any, index: number) => {
                return (
                    <div className='__card_d'>
                        <ProfileCard style={{ alignItems: 'center', gridTemplateColumns: '45px 1fr auto' }}>
                            <Avatar
                                src={item?.profilePic}
                                sx={{ bgcolor: '#575757', width: 34, height: 34, fontSize: '14px' }}
                                aria-label="recipe"
                            >
                                {item?.firstName?.slice(0, 1)}
                                {item?.lastName?.slice(0, 1)}
                            </Avatar>
                            <div className="___label">
                                <div className="name">
                                    {item?._id == user?._id ?
                                        <h1>You</h1> :
                                        <h1>{item?.firstName} {item?.lastName}</h1>}
                                </div>
                            </div>
                            <div>
                                {adimArray.some((data: any) => data.userId === item._id) && <span className='__admin'>Admin</span>}
                                {item._id !== user?._id && isAdmin &&
                                    <IconButton onClick={(e) => handleClick(e, item, index)}>
                                        {selectedIndex === index && isActionLoding ? <CircularProgress size={'20px'} color="primary" /> :
                                            <MoreVertIcon />}
                                    </IconButton>}
                            </div>
                        </ProfileCard>
                    </div>
                )
            })}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    sx: {
                      borderRadius: '16px', 
                      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)'
                    },
                  }}
            >
                <div className='classmates_list__popover'>
                    <div className='popover_header'>
                        <div className='name'>{selected?.firstName} {selected?.lastName}</div>
                        <IconButton onClick={handleClose}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                    <div className='item_list'>
                        <div onClick={() => navigate(`/user-profile/${selected?._id}`)} className='items'>View Profile</div>
                        <div onClick={() => adimArray.some((data: any) => data.userId === selected?._id) ? makeAdmin('&type=remove') : makeAdmin()} className='items'>{adimArray.some((data: any) => data.userId === selected?._id) ? 'Dismiss as Admin' : 'Make Classroom Admin'}</div>
                        <div onClick={onRemove} className='items'>Remove from Classroom</div>
                    </div>
                </div>
            </Popover>
        </div>
    );
};

export default ClassmatesListWrapper;
