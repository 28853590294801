import React, { useState, useEffect, useRef } from 'react';
import './index.scss';

const urlRegex = /(https?:\/\/[^\s]+)/g;

interface ClickableContentProps {
  content?: string;
}

const UrlContentTextWrapper: React.FC<ClickableContentProps> = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const computedStyle = window.getComputedStyle(contentRef.current);
      const lineHeight = parseFloat(computedStyle.lineHeight) || 20;
      const maxHeight = lineHeight * 4;
      setIsTruncated(contentRef.current.scrollHeight > maxHeight);
    }
  }, [content]);

  if (!content) {
    return <p className='content-wrapper'></p>;
  }

  const parts = content?.split(urlRegex);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='url_content_text_wrapper'>
      <p ref={contentRef} className={`content-wrapper ${isExpanded ? 'expanded' : ''}`}>
        {parts.map((part, index) => {
          if (urlRegex.test(part)) {
            return (
              <a key={index} className='link' href={part} target="_blank" rel="noopener noreferrer">
                {part}
              </a>
            );
          }
          return part;
        })}
      </p>
      {isTruncated && (
        <span className='read-more' onClick={handleToggle}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </span>
      )}
    </div>
  );
};

export default UrlContentTextWrapper;
