import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Event } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import GridContainer from '../../components/molecules/GridContainer';
import { useSelector } from 'react-redux';
import './index.scss'
import EventFiles from '../../components/atoms/EventFiles';
import NoNotes from '../../components/atoms/NoNotes';
import { CreateButton } from '../../components/atoms/CustomButtons';
import CreateEventModal from '../../components/atoms/CreateEventModal';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import DeleteModal from 'components/atoms/DeleteModal';

const localizer = momentLocalizer(moment);

interface CalendarEvent extends Event {
    title?: string;
    start: Date;
    end: Date;
    allDay?: boolean;
    name?: string
    startTime?: any;
    endTime?: any;
}

interface MyEvent {
    name?: string
    startTime?: any;
    endTime?: any;
    title?: string;
    start: Date;
    end: Date;
    allDay?: boolean;
}

const restrictModalData = { title: 'Access Restricted', desc: 'Sorry, only admins are allowed to add events in this classroom.', button: "Ok. got it" }

const MyCalendar: React.FC = () => {

    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const { isAdmin } = classroomData
    const [events, setEvents] = useState(classroomData.events);
    const [todaysEvents, setTodaysEvents] = useState(classroomData.todaysEvents);
    const [modalData, setModal] = useState<any>(null);
    const [createType, setCreateType] = useState<any>('');
    const [event, setEvent] = useState<any>([]);
    const [isRestricted, setRestricted] = useState(false);
    const activeClassroom = loadActiveClassroomLocalStorage()

    const logEvents = (events: CalendarEvent[]) => {
        const eventData: { title: string | undefined; start: any; end: any; }[] = [];
        events?.map(item => {
            const tempData = { title: item?.name, start: moment.utc(item?.startTime)?.toDate(), end: moment.utc(item?.endTime)?.toDate(), allDay: false }
            eventData.push({ ...tempData, ...item })
            setEvent(eventData)
            return null;
        });
    };

    useEffect(() => {
        setEvents(classroomData.events)
    }, [classroomData.events]);

    useEffect(() => {
        setTodaysEvents(classroomData.todaysEvents)
    }, [classroomData.todaysEvents]);

    useEffect(() => {
        logEvents(events);
    }, [events]);

    const handleSelectEvent = (event: any) => {
        setModal(event)
    };

    const onCreateNew = () => {
        if (activeClassroom?.memberPermissions?.academic || isAdmin) {
            setCreateType('Events')
        }
        else setRestricted(true)
    }

    return (
        <GridContainer
            rightOverflowY='scroll'
            pageTitle={'Events'}
            leftGridPartition={events?.length == 0 ? 1 : 2 / 3}
            right={<EventFiles event={events} setModal={setModal} todaysEvent={classroomData?.todaysEvents} type='Events' />}>
            <div className='create-button-wrapper'>
                <CreateButton bgColor={'#575757'} onClick={onCreateNew}>
                    Create Event
                </CreateButton>
            </div>
            <div className='events-calender'>
                {event?.length > 0 &&
                    <Calendar
                        localizer={localizer}
                        events={event}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        onSelectEvent={handleSelectEvent}
                    />}
            </div>
            <CreateEventModal
                open={createType == 'Events'}
                modalStyle={{ padding: '0px' }}
                createType={createType}
                handleClose={() => setCreateType('')} />
            <DeleteModal
                open={isRestricted}
                onSubmit={() => setRestricted(false)}
                data={restrictModalData}
                modalStyle={{ padding: '0px' }}
                handleClose={() => setRestricted(false)}
            />
            <div className={'dropdown-menu'} >
                <EventFiles modalData={modalData} setModal={setModal} event={events} todaysEvent={todaysEvents} type='Events' />
            </div>
            {events?.length == 0 &&
                <NoNotes type={'Events'} />}
        </GridContainer>
    );
};

export default MyCalendar;
