import React, { useEffect, useState } from 'react';
import './index.scss';
import GridContainer from '../../components/molecules/GridContainer';
import {  useSelector } from 'react-redux';
import { SingleFeed, WriteOnBoard } from './styles';
import RecentActivities from 'pages/RecentActivities';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import MyClassRoom from '../../components/molecules/MyClassRoom';
import UserCard from '../../components/atoms/UserCard';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from '../../components/atoms/SnackbarProvider';
import CreatePostModal from '../../components/atoms/CreatePostModal';
import PostFooter from '../../components/atoms/PostFooter';
import PostImages from '../../components/atoms/PostImages';
import PostDoc from '../../components/atoms/PostDoc';
import PostUrls from '../../components/atoms/PostUrls';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { colors } from 'assets/styles/colors';
import ModalView from '../../components/atoms/ModalView';
import PrimaryButton from '../../components/atoms/CustomButtons';
import { IoIosClose } from "react-icons/io";
import CreatePostEditModal from '../../components/atoms/CreatePostEditModal';
import { checkType, getTypeString, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CommonDeleteModal from '../../components/atoms/CommonDeleteModal';
import UrlContentTextWrapper from '../../components/atoms/UrlContentTextWrapper';
import { useCustomSWR } from 'utils/customHooks';
import DeleteModal from 'components/atoms/DeleteModal';

const leaveData = { title: 'Access Restricted', desc: 'Sorry, only admins are allowed to post in this classroom.', button: "Ok. got it" }

const Feed: React.FC = () => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const user = loadUserFromLocalStorage();
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const { isAdmin } = classroomData
    const activeClassroom = loadActiveClassroomLocalStorage()
    const { metadata } = classroomData || {}
    const subjects = classroomData?.subjectList
    const [feed, setFeed] = useState<any>([]);
    const [page, setPage] = useState(0);
    // const [isLastPage, setIsLastPage] = useState(false);
    const [isLoding, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [isRestricted, setRestricted] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [anchorElPost, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selected, setSelected] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState<any>(null)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [reportModal, setReportModal] = useState<boolean>(false)
    const [reportItem, setReportItem] = useState<any>(null)
    const [reportLoading, setReportLoading] = useState<boolean>(false)
    const [reportSuccess, setReportSuccess] = useState<boolean>(false)
    const [reportReasons, setreportReasons] = useState([])

    let params = {
        id: activeClassroom?._id,
        type: 'classRoomId',
        offset: page,
    };

    const { data, isLoading, isError, mutate } = useCustomSWR(restAPIs.getFeed(params));

    useEffect(() => {
        if (!isLoading && feed?.length == 0) {
            fetchReportReason()
        }
    }, [metadata]);

    useEffect(() => {
        if (data) {
          setFeed((prev:any) =>page===0?data?.data?.posts: [...prev,...data?.data?.posts]);
        }
      }, [data]);

    const fetchReportReason = async () => {
        const res = await fetchData(restAPIs.getReportReason(),navigate)
        if (res.status === 200 && res.data) {
            setreportReasons(res.data)
        }
    }

    const loadMore = () => {
        if (!data?.data?.isLastPage) {
            setPage(prevPage => prevPage + 1);
        }
    }

    const onEditPost = () => {
        handleMenuClose()
        setEditOpen(true)
    }

    const onDeletePost = () => {
        handleMenuClose()
        setDeleteModal(true)
    }

    const onReportPost = () => {
        setReportModal(true)
        setReportSuccess(false)
        handleMenuClose()
        setReportItem(null)
    }

    const onDelete = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getDeletePost(selected?._id),navigate)
        if (res?.data && res.status == 200) {
            let temp = [...feed]
            temp.splice(selectedIndex, 1);
            setFeed([...temp])
            showSnackbar(`Post deleted successfully`, 'success')
            setDeleteModal(false)
            mutate()
        }
        else {
            showSnackbar(`Something went wrong`, 'error')
        }
        setLoading(false)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>, item: any, index: any) => {
        setAnchorEl(event.currentTarget);
        setSelected(item)
        setSelectedIndex(index)
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // const fetchPosts = async () => {
    //     setLoading(true)
    //     let params = {
    //         id: activeClassroom?._id,
    //         type: 'classRoomId',
    //         offset: page,
    //     };
    //     const res = await fetchData(restAPIs.getFeed(params))
    //     setLoading(false)
    //     if (res.status === 200 && res.data) {
    //         setFeed(feed.concat(res.data.posts))
    //         setIsLastPage(res.data.isLastPage);
    //         setPage(page + 1)
    //     }
    // }

    const onReportSubmit = async () => {
        if (reportSuccess) {
            setReportModal(false)
        }
        else {
            if (reportItem) {
                let params = {
                    type: reportItem?.type,
                };
                setReportLoading(true)
                const res = await fetchData(restAPIs.getReportPost(selected?._id, params),navigate)
                setReportLoading(false)
                if (res.status === 200) {
                    setReportSuccess(true)
                }
                else {
                    showSnackbar(`Apologies, we were unable to process your report. Please try again later.`, 'info')
                }
            }
            else {
                showSnackbar(`Please choose an option`, 'warning')
            }
        }
    }

    const ReportModal = () => {
        return (
            <div className='delete-confirmation'>
                <div className='__header'>
                    <h4 className='class-switch-text'>{!reportSuccess ? 'Report' : 'Thanks for reporting this post'}</h4>
                    <button onClick={() => setReportModal(false)}><IoIosClose /></button>
                </div>
                {!reportSuccess &&
                    <div>
                        {reportReasons?.map((item: any, index: any) => {
                            return (
                                <div onClick={() => setReportItem(item)} className={`report-item ${reportItem == item ? 'selected' : ''}`}>{item?.type}</div>
                            )
                        })}
                    </div>}
                {reportSuccess &&
                    <div className='report-text'> We'll review the post to determine whether it violates our Policies.
                        Thanks for helping us keep Classteam safe.</div>}
                <div className='button-wrapper'>
                    <PrimaryButton isLoading={reportLoading} onClick={onReportSubmit}>{reportSuccess ? 'Ok' : 'Report'}</PrimaryButton>
                </div>
            </div>
        )
    }

    const handleClose = () => {
        setEditOpen(false)
        setOpen(false)
        setSelected(null)
        setSelectedIndex(null)
    };

    const getSubjectName = (details: any) => {
        let name = '';
        let subId = details?.subjectId ?? '';
        subjects?.length > 0 &&
            subjects.map((sub: any) => {
                if (sub._id == subId) {
                    name = sub.name;
                }
            });
        return name;
    };

    const handleOpen = () => setOpen(true);

    const onShow = (item: any) => {
        const type = item?.type
        if (type) {
            if (type == 'NOTE') {
                navigate(`/notes/ref/${item.refId}`)
            }
            if (type == 'DOCUMENT') {
                navigate(`/documents/ref/${item.refId}`)
            }
            if (type == 'EVENT') {
                navigate(`/events`)
                //   navigation.navigate('EventDetails', {eventId: id});
            }
        }
    };

    const formatDate = (dateStr: string) => {
        const dateObj = moment.utc(dateStr);
        const year = dateObj.format('YYYY')
        const monthName = dateObj.format('MMM');
        const day = dateObj.format('DD');
        const time = dateObj.format('h:mm A');
        return { year, month: monthName, day, time };
    };

    const openPostModal=()=>{
        if(activeClassroom?.memberPermissions?.board||isAdmin)
        setOpen(true)
    else setRestricted(true)
    }
    
    return (
        <GridContainer
            loading={isLoading}
            loadMore={loadMore}
            page={page}
            pageTitle={'Home'}
            right={<RecentActivities />}
            leftGridPartition={3/4}
            >
            <MyClassRoom />
            <div className='__feed_flow' style={{ marginBottom: '20vh' }}>
                <WriteOnBoard onClick={openPostModal}>
                    <span>Write On Board…</span>
                    <div className='__buttons'>
                        <i className="fi fi-rr-picture"></i>
                        <i className="fi fi-rr-link"></i>
                        <i className="fi fi-rr-clip"></i>
                        <button>POST</button>
                    </div>
                </WriteOnBoard>
                {feed?.map((item: any, index: number) => {
                    const { year, month, day, time } = formatDate(item?.startTime)||{}
                    const endDate = formatDate(item?.endTime)
                    return (
                        <div key={item?._id}>
                            <SingleFeed key={index}>
                                <UserCard handleClick={handleClick} getType={getTypeString(item?.type)} selectedItem={item} selectedIndex={index} item={item} actionEnabled={(item.type === 'NOTE' || item.type === 'DOCUMENT' || item.type === 'EVENT') ? false : true}>
                                    {item == selected &&
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorElPost}
                                            keepMounted
                                            open={Boolean(anchorElPost)}
                                            onClose={handleMenuClose}
                                            
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            className='___menu'
                                            PaperProps={{
                                                sx: {
                                                    borderRadius: '10px',
                                                    boxShadow: 'none',
                                                    border:'1px solid #eee'
                                                },
                                            }}
                                        >
                                            {item?.userId?._id == user?._id &&
                                                <MenuItem className='item-container' onClick={onEditPost}>
                                                    Edit
                                                </MenuItem>}

                                            {item?.userId?._id == user?._id &&
                                                <MenuItem className='item-container' onClick={() => onDeletePost()}>
                                                    Delete
                                                </MenuItem>}
                                            {item?.userId?._id !== user?._id &&
                                                <MenuItem className='item-container' onClick={onReportPost}>
                                                    Report
                                                </MenuItem>}
                                        </Menu>}
                                </UserCard>
                                <div style={{paddingLeft:'45px'}} onClick={() => onShow(item)}>
                                    {(item.type === 'NOTE' ||
                                        item.type === 'DOCUMENT' )&& getSubjectName(item)?.length > 0 ? (
                                        <Tooltip title={getSubjectName(item)}>
                                            <div className='subject-tab'>{getSubjectName(item)}</div>
                                        </Tooltip>) : null}
                                    {checkType(item.type) && (
                                        <p className='feed-subject-title'>
                                            {item.title}
                                        </p>
                                    )}
                                    <UrlContentTextWrapper content={item?.content}/>
                                    {item?.type === 'EVENT' && <div className="__event_sec">
                                        <div className="__sing_ev_sc">
                                            <span>Start Date</span>
                                            <div>{month} {day} {year} - {time}</div>
                                        </div>
                                        <div className="__sing_ev_sc">
                                            <span>End Date</span>
                                            <div>{endDate?.month} {endDate?.day} {endDate?.year} - {endDate?.time}</div>
                                        </div>
                                    </div>}
                                    <PostImages item={item.documents} />
                                    <PostDoc item={item.documents} />
                                    <PostUrls item={item.externalInfo} />
                                </div>
                                <PostFooter item={item} from='posts'/>
                            </SingleFeed>
                        </div>
                    )
                })}
                <CreatePostModal
                    open={open}
                    feed={feed}
                    setFeed={setFeed}
                    modalStyle={{ padding: '0px' }}
                    handleClose={handleClose}
                    handleOpen={handleOpen} />
                <CreatePostEditModal
                    open={editOpen}
                    selectedIndex={selectedIndex}
                    feed={feed}
                    setFeed={setFeed}
                    editItem={selected}
                    handleClose={handleClose}
                    handleOpen={handleOpen} />
                {isLoading && <div style={{marginTop:'20px'}}><CircularProgress size={'20px'} color="primary" /></div>}
            </div>
            <CommonDeleteModal
                open={deleteModal}
                isLoading={isLoding}
                description={`This action cannot be undone. Are you sure you want to proceed?`}
                title={'Delete Post'}
                handleClose={() => setDeleteModal(false)}
                handleSubmit={() => onDelete()}
            />
            <DeleteModal
                open={isRestricted}
                onSubmit={()=>setRestricted(false)}
                data={leaveData}
                modalStyle={{ padding: '0px' }}
                handleClose={() => setRestricted(false)}
            />
            <ModalView modalStyle={{ padding: 0 }} open={reportModal} handleClose={() => setReportModal(false)}>
                {ReportModal()}
            </ModalView>
        </GridContainer>
    );
};

export default Feed;