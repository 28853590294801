import React, { useEffect, useState } from 'react';
import './index.scss';
import GridContainer from '../../components/molecules/GridContainer';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'assets/styles/colors';
import { divide } from 'lodash';
import { CLASS_JOIN, S3UPLOAD } from 'utils/restAPIs';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Switch from '@mui/material/Switch';
import ModalView from 'components/atoms/ModalView';
import { Backdrop, CircularProgress, TextField } from '@mui/material';
import PrimaryButton from 'components/atoms/CustomButtons';
import { CLASSNAME } from 'utils/constants';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useNavigate } from 'react-router-dom';
import { clearActiveClassroom, setAcademicAccess, setActiveClassRoom, setPostAccess } from '../../redux/slices/setClassroom/classRoomSlice';
import _ from 'lodash';
import { useUpdateUser } from 'utils/customHooks';
import CustomImagePicker from 'components/atoms/CustomImagePicker';
import Axios from 'axios';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import class_logo from '../../assets/images/class_logo.png'
import class_cover from '../../assets/images/class_cover.png'
import DeleteModal from 'components/atoms/DeleteModal';
import { useMetadataContext } from 'contextApi/MetadataContext';
import ClassmatesListWrapper from 'components/atoms/ClassmatesListWrapper';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';


const ClassRoomSettings: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { showSnackbar } = useSnackbar();
    const getUpdateUser = useUpdateUser()
    const { isUpdateUserLoading} = useMetadataContext();
    const [boardData, setBoardData] = useState<any>([]);
    const [editItem, setEditItem] = useState<any>(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const [open, setOpen] = useState<boolean | string>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [logoImage, setLogoImage] = useState<File | null>(null);
    const [coverImage, setCoverImage] = useState<File | null>(null);
    const [coverLoading, setCoverLoading] = useState(false);
    const [logoLoading, setLogoLoading] = useState(false);
    const [showLeave, setShowLeave] = useState(false);
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const activeClassroom = loadActiveClassroomLocalStorage()
    const user = loadUserFromLocalStorage();
    const classRooms = user?.classRooms
    const { isAdmin } = classroomData
    const link = CLASS_JOIN + activeClassroom?._id;

    const leaveData = { title: `Leave ${activeClassroom?.name}`, desc: 'Are you sure want to leave from this classroom?', button: "Yes, I'am Sure!" }

    const data = [
        { key: 'Name', value: activeClassroom?.name },
        { key: 'ID', value: `#${activeClassroom?.classRoomId}` },
        { key: 'About', value: activeClassroom?.about, placeholder: 'Write about your classroom . . .' }
    ];

    const data2 = [
        { key: 'Joining link', value: link },
        { key: 'QR Code', value: 'Share it with your friends to join the classroom.' },
    ];

    const permissionData = [
        { key: 'Write on board', value: activeClassroom?.memberPermissions?.board ? 'Everyone' : 'Only Admins' },
        { key: 'Academic', value: activeClassroom?.memberPermissions?.academic ? 'Everyone' : 'Only Admins' },
    ];

    const settingsData = [
        { value: 'Change classroom logo' },
        { value: 'Change classroom cover' },
        { key: 'Privacy', value: activeClassroom?.public ? 'Public' : 'Private' },
        { key: 'Get latest news and updates', value: ' Keep updated with latest news and current affairs from your interest of study.' },
        { key: `Leave ${activeClassroom?.name}`, value: 'You will no longer have access to this classroom.' },
    ];

    const privacyData = [{ icon: 'fi fi-sr-earth-americas', title: 'Everyone' }, { icon: 'fi fi-sr-lock', title: open == 'privacyData' ? 'Only people with admin approval' : 'Only Admins' }];

    const getUniversityTags = () => {
        let filterTags = activeClassroom?.templateId?.filters || {};
        const clonedFilterTags = { ...filterTags };
        if (typeof clonedFilterTags === 'object' && clonedFilterTags !== null) {
            delete clonedFilterTags['filterId'];
        }
        const myArr = Object.values(clonedFilterTags);
        const finalmyArr = myArr.filter(item => item);

        setBoardData(finalmyArr);
    };

    useEffect(() => {
        getUniversityTags();
    }, [localStorage.getItem('activeClassroom')]);

    const onDataClick = (item: any) => {
        setEditItem(item)
        setOpen('editData')
    }

    const updateClassroom = async (params: any) => {
        setLoading(true)
        const res = await fetchData(restAPIs.getClassRoomPrivacy(activeClassroom?._id, params), navigate)
        let temp = [...classRooms];
        if (res.status === 200 && res.data) {
            dispatch(setActiveClassRoom(res?.data))
            setEditItem(null);
            setOpen(false)
            showSnackbar('Classroom updated', 'success')
            setLoading(false)
        }
        else{
            showSnackbar('Something went wrong', 'error')
            setLoading(false)
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let params = {
            [editItem?.key?.toLowerCase()]: editItem?.value,
        };
        updateClassroom(params)
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        if (value?.length < CLASSNAME || name !== 'Name') {
            setEditItem({ ...editItem, value: event.target.value });
            setError(null)
        }
        else {
            setError(`Maximum length allowed is ${CLASSNAME} characters`)
        }
    };

    const handleCopy = async (item: any) => {
        try {
            await navigator.clipboard.writeText(item?.value);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000); // Show success message for 2 seconds
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    };


    const RenderModal = () => (
        <div className='_classroom_edit_modal'>
            <div className='__modal'>
                <form onSubmit={(e) => handleSubmit(e)} className='login-form'>
                    {editItem?.key === 'Name' ? (
                        <TextField
                            label={editItem?.key}
                            fullWidth
                            variant="outlined"
                            error={!!error}
                            helperText={error}
                            value={editItem?.value}
                            onChange={handleChange}
                            margin="normal"
                            name={editItem?.key}
                            className="input-field"
                            InputLabelProps={{
                                style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
                            }}
                        />) :
                        <div className="input-group">
                            <textarea value={editItem?.value} onChange={handleChange} placeholder={editItem?.placeholder} />
                        </div>}
                    <div className='button-wrapper'>
                        <PrimaryButton
                            fullWidth={false}
                            isLoading={isLoading} >
                            Update
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    )

    const onSetPrivacy = (value: boolean) => {
        let params = {
            public: value,
        };
        updateClassroom(params);
    };

    const onPrivacyClick = (key: string, value: boolean) => {
        const type = key.toLowerCase() === 'academic' ? 'academic' : 'post';
        let params = {};
        if (type == 'post') {
            params = {
                memberPermissions: {
                    academic: activeClassroom?.memberPermissions?.academic,
                    board: value,
                },
            };
            if (isAdmin) {
                dispatch(setPostAccess(true))
            } else {
                dispatch(setPostAccess(value))
            }
        } else {
            params = {
                memberPermissions: {
                    academic: value,
                    board: activeClassroom?.memberPermissions?.board,
                },
            };
            if (isAdmin) {
                dispatch(setAcademicAccess(true))
            } else {
                dispatch(setAcademicAccess(value))
            }
        }
        updateClassroom(params);
    }

    const RenderPrivacyOptions = () => {
        const tempValue = open == 'privacyData' ? activeClassroom?.public : editItem?.key == permissionData[0].key ? activeClassroom?.memberPermissions?.board : activeClassroom?.memberPermissions?.academic
        return (
            <div>
                {privacyData?.map((item, index) => (
                    <div onClick={() => open == 'privacyData' ? onSetPrivacy(index == 0) : onPrivacyClick(editItem?.key, index == 0)} className='privacy_option'>
                        <i className={item.icon} />
                        {(index === 0 && tempValue || index === 1 && !tempValue) &&
                            <i className="fi fi-ss-check-circle icon"></i>}
                        <div>{item.title}</div>
                    </div>
                ))}
            </div>
        )
    }

    const RenderPermissionModal = () => (
        <div style={{ minWidth: '350px' }} className='_classroom_edit_modal _permission'>
            <div className='__modal'>
                <div className='desc'>{permissionModalDesc}</div>
                {RenderPrivacyOptions()}
            </div>
        </div>
    )

    const RenderTooltip = (item: any) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <i style={{ color: colors?.secondary, cursor: 'pointer' }} onMouseLeave={() => setCopySuccess(false)} onClick={() => handleCopy(item)} className="fi fi-rr-copy" />
            {copySuccess && (
                <span
                    style={{
                        position: 'absolute',
                        top: '-30px',
                        left: '50%',
                        marginLeft: '-30px',
                        transform: 'translateX(-50%)',
                        backgroundColor: colors.primary,
                        padding: '5px 10px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        color: colors.white,
                        whiteSpace: 'nowrap',
                    }}
                >
                    Link copied!
                </span>
            )}
        </div>
    )

    const onS3Upload = async (Image: File, imagetype: string) => {
        let params = {};
        if (imagetype == 'cover') {
            setCoverLoading(true);
        } else {
            setLogoLoading(true);
        }
        if (Image) {

            let formData = new FormData();
            if (imagetype == 'cover') {
                formData.append('file', Image, Image.name);
            }
            else if (imagetype == 'logo') {
                formData.append('file', Image, Image.name);
            }
            const config = {
                headers: {
                    'Authorization': user?.token,
                },
            };
            const response = await Axios.post(
                S3UPLOAD + `classroom/${activeClassroom?._id}/${imagetype}/${Image.name}`,
                formData,
                config,
            );
            if (response.status == 200) {
                if (imagetype == 'cover') {
                    params = {
                        cover: `classroom/${activeClassroom?._id}/${imagetype}/${Image.name}`,
                    };
                } else {
                    params = {
                        logo: `classroom/${activeClassroom?._id}/${imagetype}/${Image.name}`,
                    };
                }
                updateClassroom(params);
            } 
                setLogoLoading(false);
                setCoverLoading(false);
        }
    };

    const setUpadest = () => {
        if (!isLoading) {
            let params = {
                getUpdatesFromTemplates: !activeClassroom?.getUpdatesFromTemplates,
            };
            updateClassroom(params);
        }
    };

    const changePrivacy = (item: any) => {
        setOpen('privacyData');
        setEditItem(item)
    };

    const onLeave = async () => {
        setShowLeave(false);
        let params = {
            studentId: user?._id?.length > 0 && user?._id,
        };

        const res = await fetchData(restAPIs.getLeaveRoom(activeClassroom?._id, params,true), navigate)
        if (res?.status === 200) {
            showSnackbar('Classroom left', 'success')
            getUpdateUser()
            dispatch(clearActiveClassroom())
            navigate('/home')
        }
        else {
            showSnackbar('Something went wrong', 'error')
        }
    };

    const permissionModalTitle =open=='privacyData'?'Who can join to your classroom?': editItem?.key == permissionData[0].key ? 'Who can write on board' : 'Select who can create academic activities?'
    const permissionModalDesc = editItem?.key == permissionData[0].key ? 'Pick who can create posts on board' : 'Pick who can create notes,documents,events...etc'

    return (
        <GridContainer
            pageTitle={'Classroom Settings'}
            backButton
            leftGridPartition={3 / 4}
        >
            <div className='classroom_settings_container'>
                <div style={{
                    backgroundImage: `linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,0.0) ,rgba(0,0,0,0.2),rgba(0,0,0,.4),rgba(0,0,0,.7)),
                        url(${activeClassroom?.cover || class_cover})`,
                }}
                    className="class-room-detail-cover detail-cover">
                    <img src={activeClassroom?.logos?.small?.length > 0 ? activeClassroom?.logos?.small : class_logo} className='class_logo' />
                    <div className='detail-cover-wrapper'>
                        <span className='detail-cover-text'>{activeClassroom?.name}</span>
                        <span className='detail-cover-text id'>#{activeClassroom?.classRoomId}</span>
                    </div>
                </div>
                {/* <img src={activeClassroom?.logos?.small?.length > 0 ? activeClassroom?.logos?.small : class_logo} className='class_logo' />
            <img src={activeClassroom?.logos?.small?.length > 0 ? activeClassroom?.logos?.small : class_logo} className='class_logo' /> */}
                <div className='_classroom_settings'>
                    {data?.map((item, index) => (
                        <div key={index} className='wrapper'>
                            <div>
                                <div className='key'>{item?.key}</div>
                                <div className='value'>{(index != 2 || item?.value?.length > 0) ? item?.value : 'No description'}</div>
                            </div>
                            {isAdmin && index !== 1 && (
                                <i onClick={() => onDataClick(item)} style={{ color: colors?.secondary, cursor: 'pointer' }} className="fi fi-rr-pencil" />
                            )}
                        </div>
                    ))}
                    <div className='wrapper'>
                        <div>
                            <div className='key'>University/Board</div>
                            <div className='value'>
                                {boardData?.length > 0 ? (
                                    <>
                                        {boardData?.map((item: string, index: number) => (
                                            <div className='_board_tag' key={index}>{item}</div>
                                        ))}
                                    </>) : <div className='value'>No University/Board</div>}
                            </div>
                        </div>
                    </div>
                    {data2?.map((item, index) => (
                        <div key={index} className='wrapper' style={{ borderBottom: index == data2.length - 1 ? 'none' : '' }}>
                            <div>
                                <div className='key'>{item?.key}</div>
                                <div className='value'>{item?.value}</div>
                            </div>
                            {index == 0 ? (
                                RenderTooltip(item)
                            ) :
                                (
                                    <QrCodeIcon onClick={() => navigate('/qr-code')} style={{ color: colors.secondary, fontSize: '16px',cursor: 'pointer' }} />)}
                        </div>
                    ))}
                </div>
                <div className='title'>Classmates permission</div>
                <div className='_classroom_settings'>
                    {permissionData?.map((item, index) => (
                        <div key={index} className='wrapper' style={{ borderBottom: index == data2.length - 1 ? 'none' : '' }}>
                            <div>
                                <div className='key'>{item?.key}</div>
                                <div className='value'>{item?.value}</div>
                            </div>
                            {isAdmin && (
                                <i onClick={() => { setOpen('permissionData'); setEditItem(item) }} style={{ color: colors?.secondary, cursor: 'pointer' }} className="fi fi-rs-angle-right" />
                            )}
                        </div>
                    ))}
                </div>
                <div className='title'>Classroom settings</div>
                <div className='_classroom_settings'>
                    {settingsData?.map((item, index) => {
                        if (!isAdmin && index != 4 && index != 2) return;
                        return (
                            <div key={index} className='wrapper' style={{ borderBottom: index == settingsData.length - 1 ? 'none' : '' }}>
                                <div>
                                    <div className={index > 2 ? 'value' : 'key'} style={{ paddingBottom: index <= 2 ? '0px' : '5px', color: index == 4 ? 'red' : '' }}>{item?.key}</div>
                                    <div className={index > 2 ? 'key' : 'value'} style={{ paddingBottom: index <= 2 ? '5px' : '0px' }}>{item?.value}
                                        {index == 2 &&
                                            <i style={{ color: colors?.secondary, fontSize: '12px', marginLeft: '5px' }} className={activeClassroom?.public ? 'fi fi-rr-earth-americas:' : 'fi fi-sr-lock'} />}
                                    </div>
                                </div>
                                {(isAdmin || index == 4) &&
                                    index == 3 ? <Switch onChange={setUpadest} checked={activeClassroom?.getUpdatesFromTemplates} /> :
                                    (index == 2 || index == 4) ? <i onClick={index == 2 ? changePrivacy : () => setShowLeave(true)} style={{ color: colors?.secondary, cursor: 'pointer' }}
                                        className={'fi fi-rs-angle-right'} /> :
                                        <CustomImagePicker imageType={index == 0 ? 'logo' : 'cover'} onS3Upload={onS3Upload} id={`picker-${index}`} setSelectedImage={index == 0 ? setLogoImage : setCoverImage}>
                                            {(index == 0 && logoLoading || index == 1 && coverLoading) ? <CircularProgress size={16} sx={{ color: colors?.primary }} /> : <i style={{ color: colors?.secondary }}
                                                className={"fi fi-rr-camera"} />}
                                        </CustomImagePicker>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='_classroom_members'>
            <ClassmatesListWrapper fromSettings/>
            </div>
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading||isUpdateUserLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <DeleteModal
                open={showLeave}
                onSubmit={onLeave}
                data={leaveData}
                modalStyle={{ padding: '0px' }}
                handleClose={() => setShowLeave(false)}
            />
            <ModalView title={`Edit ${editItem?.key}`} open={open == 'editData'} handleClose={() => { setEditItem(null); setOpen(false) }} modalStyle={{ padding: 0 }}>
                {RenderModal()}
            </ModalView>
            <ModalView title={permissionModalTitle} open={open == 'permissionData' || open == 'privacyData'} handleClose={() => { setEditItem(null); setOpen(false) }} modalStyle={{ padding: 0 }}>
                {RenderPermissionModal()}
            </ModalView>
        </GridContainer>
    );
};

export default ClassRoomSettings;
