import { createSlice } from '@reduxjs/toolkit';
import { title } from 'process';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';

const classroom = {
  // errorMessage: null,
  status: 0,
  notesList: [],
  subjectList: [],
  curriculum: null,
  todaysEvents: null,
  events: null,
  documentList: [],
  metadata: null,
  isAdmin: null,
  title: '',
  isLastNote: false,
  isNoteLoading: false,
  isLastDocument: false,
  isLastSubject: false,
  isSubjectLoading: false,
  isDocumentLoading: false,
  isEventLoading: false,
  isCarriculamLoading: false,
  // addedNewPost: false,
  havePostAccesss: false,
  haveAcademicAccesss: false,
  // navigationModule: null,
  // isNewClass: false,
  // classSetupStatuses: {
  //   isClassmateAdded: true,
  //   isSubjectAdded: true,
  //   isPostAdded: true,
  // },
}

const activeClassroom = loadActiveClassroomLocalStorage()

const userSlice = createSlice({
  name: 'userDetails',
  initialState: {
    SelectedClassMetadata: null,
    SelectedClassRoom: null,
    classroomData: classroom,
  },
  reducers: {
    setSelectedClassMetadata: (state, action) => {
      state.SelectedClassMetadata = action?.payload;
    },
    setSelectedClassRoom: (state, action) => {
      state.SelectedClassRoom = action?.payload;
    },
    setUpdateNote: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 1,
        notesList: action?.payload?.offset ? state?.classroomData?.notesList?.concat(action?.payload?.notes) : action?.payload?.notes,
        isLastNote: action?.payload?.isLastPage,
        title: 'Subjects',
        isNoteLoading: false
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setLoadingNotes: (state) => {
      const classroomData = {
        ...state.classroomData,
        isNoteLoading: true
      }
      state.classroomData = classroomData
    },
    setUpdateSubject: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 2,
        subjectList: action?.payload?.offset ? state?.classroomData?.subjectList?.concat(action?.payload?.subjects) : action?.payload?.subjects,
        title: 'Curriculum',
        isLastSubject: action?.payload?.isLastPage,
        isSubjectLoading: false
      }
      state.classroomData = classroomData
    },
    setLoadingSubject: (state) => {
      const classroomData = {
        ...state.classroomData,
        isSubjectLoading: true
      }
      state.classroomData = classroomData
    },
    setUpdateCarriculam: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 3,
        curriculum: action?.payload?.curriculums,
        title: 'Todays Events',
        isCarriculamLoading: false
      }
      state.classroomData = classroomData
    },
    setLoadingCarriculam: (state) => {
      const classroomData = {
        ...state.classroomData,
        isCarriculamLoading: true
      }
      state.classroomData = classroomData
    },
    setUpdateTodaysEvent: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 4,
        todaysEvents: action?.payload?.event,
        title: 'Documents',
        isEventLoading: false
      }
      state.classroomData = classroomData
    },
    setLoadingEvent: (state) => {
      const classroomData = {
        ...state.classroomData,
        isEventLoading: true
      }
      state.classroomData = classroomData
    },
    setUpdateEvent: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 5,
        events: action?.payload?.event,
        title: 'Events',
        isEventLoading: false
      }
      state.classroomData = classroomData
    },
    setUpdateDocument: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 6,
        documentList: action?.payload?.offset ? state?.classroomData?.documentList?.concat(action?.payload?.document) : action?.payload?.document,
        title: 'Classroom',
        isLastDocument: action?.payload?.isLastPage,
        isDocumentLoading: false
      }
      state.classroomData = classroomData
    },
    setLoadingDocument: (state) => {
      const classroomData = {
        ...state.classroomData,
        isDocumentLoading: true
      }
      state.classroomData = classroomData
    },
    setActiveClassRoom: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 7,
        title: '',
      }
      state.classroomData = classroomData
      // state.activeClassroom = action?.payload;
      localStorage.setItem('activeClassroom', JSON.stringify(action?.payload));
    },
    setUpdateTimeTable: (state, action) => {
      const temp_activeClassroom = {
        ...(activeClassroom || {}),
        timeTable: action?.payload,
      }
      // @ts-ignore
      // state.activeClassroom = activeClassroom;
      localStorage.setItem('activeClassroom', JSON.stringify(temp_activeClassroom));
    },
    setClassroomData: (state, action) => {
      state.classroomData = action?.payload
    },
    setPostAccess: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        havePostAccesss: action?.payload
      }
      state.classroomData = classroomData
    },
    setAcademicAccess: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        haveAcademicAccesss: action?.payload
      }
      state.classroomData = classroomData
    },
    setUpdateUserType: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 8,
        title: '',
        isAdmin: action?.payload
      }
      state.classroomData = classroomData
    },
    setMetaData: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        metadata: action?.payload,
      }
      state.classroomData = classroomData
    },
    clearSelectedClassMetadata: (state) => {
      state.SelectedClassMetadata = null;
    },
    clearSelectedClassRoom: (state) => {
      state.SelectedClassRoom = null;
    },
    clearClassroomData: (state) => {
      state.classroomData = classroom;
    },
    clearActiveClassroom: (state) => {
      localStorage.setItem('activeClassroom', '');
    },
    clearNote: (state) => {
      state.classroomData.notesList = []
    },
  },
});

export const {
  setSelectedClassMetadata,
  setSelectedClassRoom,
  setUpdateNote,
  setLoadingNotes,
  setUpdateSubject,
  setLoadingSubject,
  setUpdateCarriculam,
  setLoadingCarriculam,
  setUpdateTodaysEvent,
  setLoadingEvent,
  setUpdateEvent,
  setUpdateDocument,
  setLoadingDocument,
  setActiveClassRoom,
  setUpdateTimeTable,
  setClassroomData,
  setPostAccess,
  setAcademicAccess,
  setUpdateUserType,
  setMetaData,
  clearSelectedClassMetadata,
  clearSelectedClassRoom,
  clearClassroomData,
  clearActiveClassroom,
  clearNote
} = userSlice.actions;
export default userSlice.reducer;
