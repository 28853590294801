import React, { useEffect, useState } from 'react';
import ModalView from '../ModalView';
import { IoIosClose } from "react-icons/io";
import './index.scss'
import PrimaryButton from '../CustomButtons';
import { SUBJECT_TITLE } from 'utils/constants';
import { Typography } from '@mui/material';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useNavigate } from 'react-router-dom';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { useSnackbar } from '../SnackbarProvider';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';

interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    createType?: string;
    editItem?: any;
    disableReroute?: boolean
}

const CreateSubjectModal: React.FC<Props> = ({ open, handleClose, modalStyle, createType, disableReroute = false, editItem }) => {

    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar();
    const { setToogleDrawer } = useMetadataContext();
    const { fetchSubject, fetchMetaData } = useFetchClassRoomItems()
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target?.value?.length < SUBJECT_TITLE) {
            setName(event.target.value);
            setError('')
        }
        else {
            setError('Maximum length exceeds')
        }
    }

    useEffect(() => {
        if (editItem) {
            setName(editItem.name)
        }
    }, [editItem]);

    const onSuccess = () => {
        showSnackbar(`${createType} ${editItem ? 'updated' : 'created'}`, 'success')
        fetchSubject(activeClassroom?._id)
        fetchMetaData()
        handleClose?.()
        if (!disableReroute) {
            navigate('/subjects')
        }
        setToogleDrawer(false)
    }

    const onSubmit = async () => {
        if (name?.trim()?.length > 0) {
            setIsLoading(true)
            let params = {
                classRoomId: activeClassroom?._id,
                name: name?.trim(),
                instructorName: '',
            };
            const res = editItem ? await fetchData(restAPIs.getupdateSubject(editItem._id, params), navigate) : await fetchData(restAPIs.getAddSubject(params), navigate)
            setIsLoading(false)
            if (res.status === 200 && res.data) {
                onSuccess()
            }
            else {
                showSnackbar('something went wrong !', 'error')
            }
        }
        else {
            setError('* required')
        }
    }

    const onClose = () => {
        handleClose?.()
        setName('')
        setError('')
    }

    const RenderModal = () => (
        <div className="create-subject-modal">
            <div className='__header'>
                <h4>{editItem ? 'Edit' : 'Create'} {createType}</h4>
                <button onClick={onClose}><IoIosClose /></button>
            </div>
            <div className="file-upload-container">
                <div className="input-group">
                    <label>Subject</label>
                    <input value={name} type="text" onChange={handleSubjectChange} placeholder={`${createType} name`} />
                    <Typography sx={{ color: 'red' }} variant='subtitle2'>{error}</Typography>
                </div>
            </div>
            <div className='modal-footer-button'>
                <PrimaryButton
                    fullWidth={false}
                    onClick={onSubmit}
                    isLoading={isLoading}>
                    {editItem ? 'Update' : 'Create'} {createType}
                </PrimaryButton>
            </div>
        </div>
    )
    return (
        <ModalView open={open} handleClose={onClose} modalStyle={modalStyle}>
            {RenderModal()}
        </ModalView>
    )
};

export default CreateSubjectModal;
