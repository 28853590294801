import GridContainer from 'components/molecules/GridContainer';
import React, { useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';
import './index.scss'
import { CLASS_JOIN } from 'utils/restAPIs';
import Images from 'assets/image';
import class_logo from '../../assets/images/class_logo.png'
import logo from '../../assets/images/classteamlogo.png'
import html2canvas from 'html2canvas';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
function QrCode() {

    const activeClassroom = loadActiveClassroomLocalStorage()
    const link = CLASS_JOIN + activeClassroom?._id;

    const qrCodeRef = useRef<HTMLDivElement>(null);

    const handleShare = async () => {
        if (!qrCodeRef.current) return;

        try {
            const canvas = await html2canvas(qrCodeRef.current);
            const blob = await new Promise<Blob>((resolve) => {
                canvas.toBlob((blob) => {
                    if (blob) resolve(blob);
                }, 'image/png');
            });
            if (navigator.share) {
                await navigator.share({
                    files: [new File([blob], 'qr-code.png', { type: 'image/png' })],
                    title: 'Classteam',
                    text: 'Scan QR Code to join classroom',
                });
            } else {
                // Fallback option: you can implement a custom share dialog or download the image
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'qr-code.png';
                a.click();
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error('Error sharing QR code:', error);
        }
    };

    return (
        <GridContainer
            pageTitle={'QR Code'}
            backButton
            leftGridPartition={window?.innerWidth<600?1: 3 / 4}
        >
            <div className='qrcode__wrapper'>
                <div ref={qrCodeRef} className='qrcode'>
                    <img src={class_logo} className='class_logo' />
                    <div className='qrcode__container'>
                        <div className='qrcode__title'>{activeClassroom?.name}</div>
                        <div className='qrcode__id'>#{activeClassroom?.classRoomId}</div>
                        <QRCode value={link} logoImage={Images.BlackLogo} size={250} />
                    </div>
                    <img src={logo} className='classteam_logo' />
                    <div className='qrcode__footer'>
                        class
                        <div>team</div>
                    </div>
                </div>
                <div onClick={handleShare} className='share__container'>
                    <i className="fi fi-rr-upload"></i>
                    <div className='share'>Share classroom code</div>
                </div>
            </div>
        </GridContainer>
    );
}

export default QrCode;
