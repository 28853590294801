
import React, { useEffect, useState } from 'react';
import './index.scss';
import GridContainer from '../../components/molecules/GridContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PrimaryButton, { CreateButton, SecondryButton } from '../../components/atoms/CustomButtons';

import { Select, MenuItem, List, ListItem } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSnackbar } from '../../components/atoms/SnackbarProvider';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { setUpdateTimeTable } from '../../redux/slices/setClassroom/classRoomSlice';
import { useNavigate } from 'react-router-dom';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import DeleteModal from 'components/atoms/DeleteModal';

const restrictModalData = { title: 'Access Restricted', desc: 'Sorry, only admins are allowed to change timetable in this classroom.', button: "Ok. got it" }
const initialTimes = ['09:30 am', '10:30 am', '11:30 am', '12:30 pm', '01:30 pm', '02:30 pm', '03:30 pm', '04:30 pm', '05:30 pm', '06:30 pm', '07:30 pm', '08:30 pm'];
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const abbreviatedDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const Timetable: React.FC = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const { isAdmin } = classroomData
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [subjectList, setSubjectList] = useState<any>([])
    const [currentTime, setCurrentTime] = useState(dayjs());

    const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
    const [data, setData] = useState(activeClassroom.timeTable);

    const [originalData, setOriginalData] = useState(data);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditTiming, setIsEditTiming] = useState(false);

    const [isLoadingTimetable, setIsLoadingTimeTable] = useState(false)
    const [isLoadingSession, setIsLoadingSession] = useState(false)
    const [isLoadingInitial, setIsLoadingInitial] = useState(false)

    const [isUpdated, setIsUpdated] = useState(false);
    const [initialData, setInitialData] = useState<any>(activeClassroom?.timeTable)
    const [isRestricted, setRestricted] = useState(false);


    useEffect(() => {
        currentTimeTable(true);
        currentSubjectList();
        const timer = setInterval(() => {
            setCurrentTime(dayjs());
        }, 60000);
        return () => clearInterval(timer);
    }, []);


    const currentSubjectList = async () => {
        const res = await fetchData(restAPIs.getSubjects(activeClassroom?._id), navigate)
        if (res?.data?.subjects?.length > 0)
            setSubjectList(res?.data.subjects)
    }

    const currentTimeTable = async (disableResetActiveClass?: boolean) => {
        const res = await fetchData(restAPIs.getActiveClassDetails(activeClassroom?._id), navigate)

        if (disableResetActiveClass !== true) {
            dispatch(setUpdateTimeTable(res?.data?.timeTable))
        }
        setData(res?.data?.timeTable)
        setOriginalData(res?.data?.timeTable)
        setInitialData(res?.data?.timeTable)
    }

    const isCurrentTime = (startTime: any, endTime: any) => {
        const now = currentTime;
        return now.isAfter(dayjs(startTime)) && now.isBefore(dayjs(endTime));
    };

    const getCurrentDay = () => {
        return currentTime.format('ddd').toLowerCase();
    };

    const currentDay = getCurrentDay();

    const handleSelect = (subjectId: string) => {
        setSelectedSubject(subjectId);
    };

    const handleCellClick = (dayIndex: number, timeIndex: number, sectionId: any) => {
        setData((prevData: any) => {
            // Create a deep copy of the previous data
            const newData = JSON.parse(JSON.stringify(prevData));

            // Ensure dayData exists at the given index
            const dayData = newData.data[dayIndex];
            if (!dayData) return prevData;

            // Ensure subjects array exists
            if (!dayData.subjects) {
                dayData.subjects = [];
            }

            // Retrieve the current subject in this slot (if it exists)
            const currentSubjectIndex = dayData.subjects.findIndex(
                (subject: any) => subject.sectionId === sectionId
            );
            const selectedSubjectData = subjectList.find((subject: any) => subject._id === selectedSubject);

            if (selectedSubjectData) {
                if (currentSubjectIndex !== -1) {
                    const currentSubject = dayData.subjects[currentSubjectIndex];
                    if (currentSubject.subject._id === selectedSubjectData._id) {
                        // If the selected subject is the same as the current subject, remove it
                        dayData.subjects.splice(currentSubjectIndex, 1);
                    } else {
                        // Replace the current subject with the selected one
                        dayData.subjects[currentSubjectIndex] = {
                            startTime: newData.timings[timeIndex]?.startTime || null,
                            endTime: newData.timings[timeIndex]?.endTime || null,
                            subject: {
                                ...selectedSubjectData,
                                subjectId: selectedSubjectData.subjectId || selectedSubjectData._id,
                            },
                            sectionId: sectionId,
                        };
                    }
                } else {
                    // If there is no current subject, push the selected subject to the subjects array
                    dayData.subjects.push({
                        startTime: newData.timings[timeIndex]?.startTime || null,
                        endTime: newData.timings[timeIndex]?.endTime || null,
                        subject: {
                            ...selectedSubjectData,
                            subjectId: selectedSubjectData.subjectId || selectedSubjectData._id,
                        },
                        sectionId: sectionId,
                    });
                }
            }

            // Check for changes in data
            if (JSON.stringify(newData) !== JSON.stringify(originalData)) {
                setIsUpdated(true);
            } else {
                setIsUpdated(false);
            }

            return newData;
        });
    };

    const formatTimeRange = (startTime: string, endTime: string) => {
        const start = dayjs(startTime);
        const end = dayjs(endTime);

        const startFormatted = start.format('hh:mm');
        const endFormatted = end.format('hh:mm A');

        const startPeriod = start.format('A');
        const endPeriod = end.format('A');

        // if (startPeriod === endPeriod) {
        //     return `${startFormatted} - ${end.format('hh:mm A')}`;
        // } else {
        //     return `${startFormatted} ${startPeriod} - ${endFormatted}`;
        // }
        return `${startFormatted} ${startPeriod} `;
    };

    const onEditClick = () => {
        if(activeClassroom?.memberPermissions?.academic||isAdmin){
        setIsEdit(true);
        setIsEditTiming(false);
        }
        else setRestricted(true)
    };

    const handleUpdateClick = async () => {
        const updatedTimetable = {
            noOfSessions: data.noOfSessions,
            noOfDays: data.noOfDays,
            timings: data.timings,
            data: data.data.map((day: any) => ({
                _id: day._id,
                day: day.day,
                subjects: day.subjects.map((sub: any, index: number) => {
                    if (sub) {
                        return {
                            sectionId: sub?.sectionId,
                            startTime: data.timings[index].startTime,
                            endTime: data.timings[index].endTime,
                            subjectId: sub.subject?._id ?? sub.subject?.subjectId,
                            name: sub?.subject?.name,
                            subject: {
                                "classRoomId": sub?.subject?.classRoomId,
                                "createdAt": sub?.subject?.createdAt,
                                "documentsCount": sub?.subject?.documentsCount,
                                "notesCount": sub?.subject?.notesCount,
                                "updatedAt": sub?.subject?.updatedAt,
                                "__v": sub?.subject?.__v,
                                "_id": sub?.subject?._id,
                                name: sub?.subject?.name,
                                subjectId: sub.subject?._id ?? sub.subject?.subjectId,
                                sectionId: sub?.sectionId,
                            }
                        };
                    } else {
                        return null;
                    }
                }).filter((subject: any) => subject !== null) // Filter out null subjects
            }))
        };

        // Here you can send the `updatedTimetable` to your backend using fetchData or any other method
        setIsLoadingTimeTable(true)
        const res = await fetchData(restAPIs.updateTimetable(activeClassroom?._id, updatedTimetable), navigate)
        if (res.status == 200) {
            currentTimeTable();
            showSnackbar('Timetable updated successfully', 'success')
        }
        else {
            showSnackbar('something went wrong !', 'error')
        }
        setIsLoadingTimeTable(false)
        setIsUpdated(false);
        setIsEdit(false);
    };

    const handleCancelClick = () => {
        setData(originalData);
        setIsUpdated(false);
        setIsEdit(false);
        setSelectedSubject(null)
    };

    // sessions
    const [sessions, setSessions] = useState<{
        startTime: dayjs.Dayjs;
        endTime: dayjs.Dayjs;
        _id: string;
        startIsValid: boolean;
        endIsValid: boolean;
        startErrorMessage: string;
        endErrorMessage: string;
    }[]>([]);
    const [sessionCount, setSessionCount] = useState<number>(initialData?.noOfSessions);
    const [days, setDays] = useState<string[]>(initialData?.data.map((day: any) => daysOfWeek[abbreviatedDays.indexOf(day.day)]));

    useEffect(() => {
        initilSessionSet();
    }, [initialData]);

    const initilSessionSet = () => {
        const newSessions = initialData?.timings.map((timing: any, index: any) => {
            const start = dayjs(timing.startTime);
            const end = dayjs(timing.endTime);
            return {
                startTime: start,
                endTime: end,
                _id: timing._id,
                startIsValid: true,
                endIsValid: true,
                startErrorMessage: '',
                endErrorMessage: '',
            };
        });

        setSessions(newSessions);
    }

    const generateSessions = (count: number) => {
        const newSessions = initialTimes?.slice(0, count).map((startTime, index) => {
            const start = dayjs(startTime, 'hh:mm a');
            const end = dayjs(start).add(1, 'hours');
            return {
                startTime: start,
                endTime: end,
                _id: (index + 1).toString(),
                startIsValid: true,
                endIsValid: true,
                startErrorMessage: '',
                endErrorMessage: '',
            };
        });

        setSessions(newSessions);
    };

    const updateSessionTime = (id: string, time: dayjs.Dayjs, type: 'start' | 'end') => {
        setSessions(prevSessions =>
            prevSessions.map(session => {
                if (session._id === id) {
                    const updatedSession = { ...session };
                    if (type === 'start') {
                        updatedSession.startTime = time;
                        if (time.isBefore(updatedSession.endTime)) {
                            updatedSession.startIsValid = true;
                            updatedSession.startErrorMessage = '';
                            updatedSession.endIsValid = true;
                            updatedSession.endErrorMessage = '';
                        } else {
                            updatedSession.startIsValid = false;
                            updatedSession.startErrorMessage = 'Start time must be before end time';
                        }
                    } else {
                        updatedSession.endTime = time;
                        if (time.isAfter(updatedSession.startTime)) {
                            updatedSession.endIsValid = true;
                            updatedSession.endErrorMessage = '';
                            updatedSession.startIsValid = true;
                            updatedSession.startErrorMessage = '';
                        } else {
                            updatedSession.endIsValid = false;
                            updatedSession.endErrorMessage = 'End time must be after start time';
                        }
                    }
                    return updatedSession;
                }
                return session;
            })
        );
    };

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        setDays(event.target.value as string[]);
    };

    const generateOutput = async () => {
        const output = {
            noOfSessions: sessions?.length,
            noOfDays: days?.length,
            data: days.map(day => ({
                day: abbreviatedDays[daysOfWeek.indexOf(day)],
                subjects: []
            })),
            timings: sessions.map(session => ({
                startTime: session.startTime.toISOString(),
                endTime: session.endTime.toISOString()
            }))
        };
        setIsLoadingSession(true)
        const res = await fetchData(restAPIs.updateTimetable(activeClassroom?._id, output), navigate);
        setIsLoadingSession(false)
        if (res.status === 200) {
            currentTimeTable();
            handleClose();
            setIsEdit(false)
            setIsEditTiming(false);
            showSnackbar('Timetable updated successfully', 'success');
        } else {
            showSnackbar('Something went wrong!', 'error');
        }
    };

    const onEditTimingClick = () => {
        if(activeClassroom?.memberPermissions?.academic||isAdmin){
        setIsEdit(false)
        setIsEditTiming(true);
        }
        else setRestricted(true)
    };

    const handleCancelTimingClick = () => {
        setIsEditTiming(false);
        setSessionCount(initialData?.noOfSessions)
        initilSessionSet();
        setDays(initialData?.data.map((day: any) => daysOfWeek[abbreviatedDays.indexOf(day.day)]))
    };


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <GridContainer pageTitle={'Timetable'} leftGridPartition={3 / 4}>

            <Backdrop
                sx={{ color: '#fff', zIndex: 1, background: '#00000014' }}
                open={isLoadingInitial}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{ marginBottom: '15vh' }}>
                <div>
                    <div className='create-button-wrapper __two_btns'>
                        <CreateButton bgColor={isEdit ? '#575757' : '#e2e2e2'} color={isEdit ? '#fff' : '#575757'} onClick={onEditClick}>
                            Edit Timetable
                        </CreateButton>
                        <CreateButton bgColor={isEditTiming ? '#575757' : '#e2e2e2'} color={isEditTiming ? '#fff' : '#575757'} onClick={onEditTimingClick}>
                            Edit Timings & Sessions
                        </CreateButton>
                    </div>
                    {!isEditTiming && <fieldset disabled={!isEdit}>
                        {isEdit && (
                            <div className="single-select-container">
                                {subjectList.map((subject: any) => (
                                    <Tooltip title={subject.name} key={subject._id}>
                                        <div
                                            onClick={() => handleSelect(subject._id)}
                                            className={`single-select-item ${selectedSubject === subject._id ? 'selected' : ''}`}
                                        >
                                            {subject.name}
                                        </div>
                                    </Tooltip>
                                ))}
                            </div>
                        )}
                        <div className="timetable-container">
                            <Paper elevation={3} className="timetable-paper">
                                <Grid container style={{ flexWrap: 'nowrap' }}>
                                    <Grid item xs={2} className="timetable-header">
                                        <Typography variant="h6" style={{ fontWeight: '700', fontSize: '14px' }}>Timing</Typography>
                                    </Grid>
                                    {data.data.map((day: any, index: any) => (

                                        <Grid item xs={12 / (data.noOfDays)} key={index} className={`timetable-header ${day.day === currentDay ? 'current-day' : ''}`}>
                                            <Typography variant="h6" style={{ fontWeight: '700', fontSize: '14px' }}>{day.day.toUpperCase()}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                                {data.timings.map((time: any, timeIndex: any) => (
                                    timeIndex < data?.noOfSessions &&
                                    <Grid container key={time._id} className={`timetable-row ${isCurrentTime(time.startTime, time.endTime) ? 'current-time' : ''}`}>
                                        <Grid item xs={2} className="timetable-timing">
                                            <Typography variant="body2" className="__time">
                                                {formatTimeRange(time.startTime, time.endTime)}
                                            </Typography>
                                        </Grid>
                                        {data.data.map((day: any, dayIndex: any) => {
                                            const sectionId = (timeIndex * data.noOfDays) + dayIndex + 1 + timeIndex;
                                            const subjectData = day.subjects.find((sub: any) => sub?.sectionId === sectionId);


                                            if (subjectData) {
                                                const subject = subjectData.subject;
                                                return (
                                                    <Grid item xs={12 / (data.noOfDays)} key={dayIndex} className={`timetable-cell ${day.day === currentDay ? 'current-day-cell' : ''}`} onClick={() => handleCellClick(dayIndex, timeIndex, sectionId)}>
                                                        <div className="subject">
                                                            {subject ? subject.name : ''}
                                                        </div>
                                                    </Grid>
                                                );
                                            } else {
                                                return (
                                                    <Grid item xs={12 / (data.noOfDays)} key={dayIndex} className={`timetable-cell ${day.day === currentDay ? 'current-day-cell' : ''}`} onClick={() => handleCellClick(dayIndex, timeIndex, sectionId)}>
                                                        <div className="subject">
                                                            {'+'}
                                                        </div>
                                                    </Grid>
                                                );
                                            }
                                        })}
                                    </Grid>
                                ))}
                            </Paper>
                            <div className='__footer-button'>
                                {isEdit && <SecondryButton
                                    fullWidth={false}
                                    onClick={handleCancelClick}
                                    bgColor={'white'}
                                >
                                    Cancel
                                </SecondryButton>}
                                {isEdit && isUpdated && <PrimaryButton
                                    fullWidth={false}
                                    onClick={handleUpdateClick}
                                    disabled={!isUpdated}
                                    isLoading={isLoadingTimetable}
                                >
                                    Update
                                </PrimaryButton>}

                            </div>
                        </div>

                    </fieldset>}
                </div>
                {isEditTiming && !isEdit && <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className='__days_list_session'>
                            <h1 className='__session_head'>
                                Days and Sessions
                            </h1>
                            <div className='__session__'>
                                <div style={{ textAlign: 'left', maxWidth: '25vw' }}>
                                    <Typography variant="h4" gutterBottom>
                                        Number of Columns (Days)
                                    </Typography>
                                    <Select
                                        multiple
                                        value={days}
                                        onChange={handleChange}
                                        style={{ width: '100%', flex: '1', height: '40px' }}
                                    >
                                        {daysOfWeek.map((day) => (
                                            <MenuItem key={day} value={day}>
                                                {day}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{ textAlign: 'left', maxWidth: '100%' }}>
                                    <Typography variant="h4" gutterBottom>
                                        Number of Row (Session)
                                    </Typography>
                                    <Select
                                        value={sessionCount}
                                        onChange={(event) => {
                                            setSessionCount(event.target.value as number);
                                            generateSessions(event.target.value as number);
                                        }}
                                        style={{ width: '100%', flex: '1', height: '40px' }}
                                    >
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <MenuItem key={i + 1} value={i + 1}>
                                                {i + 1}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <List>
                                {sessions.map((session: any, index: any) => (
                                    index < sessionCount &&
                                    <ListItem key={session._id} style={{ gap: '20px', alignItems: 'flex-start', padding: '0', marginBottom: '16px' }} >
                                        <h3 style={{ fontWeight: '500', margin: '0', marginTop: '10px', minWidth: '100px' }}>Session : {index + 1}</h3>
                                        <div className='__time_pickers___'>
                                            <TimePicker
                                                label="Start Time"
                                                value={session.startTime}
                                                onChange={(date) => updateSessionTime(session._id, dayjs(date), 'start')}
                                                slotProps={{
                                                    textField: {
                                                        helperText: !session.startIsValid ? session.startErrorMessage : '',
                                                        FormHelperTextProps: {
                                                            style: { color: 'red' },
                                                        },
                                                    },
                                                }}
                                                className="__custom-timepicker"
                                            />
                                            <TimePicker
                                                label="End Time"
                                                value={session.endTime}
                                                onChange={(date) => updateSessionTime(session._id, dayjs(date), 'end')}
                                                slotProps={{
                                                    textField: {
                                                        helperText: !session.endIsValid ? session.endErrorMessage : '',
                                                        FormHelperTextProps: {
                                                            style: { color: 'red' },
                                                        },
                                                    },
                                                }}
                                                className="__custom-timepicker"
                                            />
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                            <div className='__footer-button'>
                                <SecondryButton
                                    fullWidth={false}
                                    onClick={handleCancelTimingClick}
                                    bgColor={'white'}
                                >
                                    Cancel
                                </SecondryButton>
                                <PrimaryButton
                                    fullWidth={false}
                                    onClick={() => setOpen(true)}
                                >
                                    Update
                                </PrimaryButton>

                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        Confirm
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            By changing sessions the whole timetable data will wipe out.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <a style={{ fontSize: '15px', cursor: 'pointer' }} onClick={handleClose}>Cancel</a>
                                        <PrimaryButton
                                            fullWidth={false}
                                            onClick={generateOutput}
                                            isLoading={isLoadingSession}
                                        >
                                            Confirm
                                        </PrimaryButton>

                                    </DialogActions>
                                </Dialog>

                            </div>
                        </div>
                    </LocalizationProvider>
                </div>}
                <DeleteModal
                open={isRestricted}
                onSubmit={()=>setRestricted(false)}
                data={restrictModalData}
                modalStyle={{ padding: '0px' }}
                handleClose={() => setRestricted(false)}
            />
            </div>
        </GridContainer>
    );
};

export default Timetable;