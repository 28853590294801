import React, { useState } from 'react';
import './index.scss';
import { getTime, loadUserFromLocalStorage } from 'utils/commonUtils';
import { colors } from 'assets/styles/colors';
import NoNotes from '../NoNotes';
import DownloadFileComponent from '../DownloadFileComponent';
import { getFileType } from 'utils/commonUtils';
import { CreateButton } from '../CustomButtons';
import CreateNoteModal from '../CreateNoteModal';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useSnackbar } from '../SnackbarProvider';
import { CircularProgress } from '@mui/material';
import CommonDeleteModal from '../CommonDeleteModal';
import { useNavigate } from 'react-router-dom';



interface CustomNoteFilesProps {
    notes: any;
    type?: string;
    setNotes?: React.Dispatch<React.SetStateAction<any>>;
    notesArray?: any;
    selected?: number | null;
    loadMore?: (initialPage: boolean) => void;
    fullScreen?:boolean
    detailRefresh?:()=>void
}

const style = { minWidth: '40px' }
const fileType = ['pdf', 'video', 'doc', 'img', 'file']

const NoteFiles: React.FC<CustomNoteFilesProps> = ({ notes, type = 'Notes',detailRefresh, setNotes, notesArray, selected,fullScreen }) => {


    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const user = loadUserFromLocalStorage();
    const [createType, setCreateType] = useState<any>('');
    const [isLoading, setIsLoading] = useState<string | null>(null)
    const [editItem, setEditItem] = useState<any>(null);
    const [deleteModal, setDeleteModal] = useState<string | null>(null)
    const isOwned = user?._id == notes?.userId?._id || user?._id == notes?.createdBy?._id

    function getImageName(note: any) {

        switch (getFileType(note)) {
            case 'PDF':
                return fileType[0];
            case 'Video':
                return fileType[1];
            case 'Document':
                return fileType[2];
            case 'Image':
                return fileType[3];
            case 'File':
                return fileType[4];
        }
        return fileType[4];
    }

    const onCreateNew = () => {
        setEditItem(notes)
        setCreateType(type == 'Notes' ? 'Note' : 'Document')
    }

    const onDeletFile = async (id: string | null) => {
        setDeleteModal(null)
        setIsLoading(id);
        let deleteurl = '';
        notes.files.map((item: any) => {
            if (item._id == id) {
                deleteurl = item.url;
            }
        });
        let noteId = notes._id;
        let temp_notes = { ...notes };
        let temp_files = temp_notes.files.filter((e: any) => e._id != id);
        temp_notes.files = temp_files;
        let params = temp_notes;
        const res = type == 'Notes' ? await fetchData(restAPIs.getupdateNote(noteId, params),navigate) :
            await fetchData(restAPIs.getupdateDocuments(noteId, params),navigate)
        setIsLoading(null);
        if (res.status == 200 && res.data) {
            let tempNotesArray = [...notesArray]
            if (selected === 0 || selected) {
                tempNotesArray[selected] = {
                    ...tempNotesArray[selected],
                    files: res.data?.files
                };
                setNotes?.(tempNotesArray)
            }
            const S3DeleteRes = await fetchData(restAPIs.getS3Delete(deleteurl),navigate)
            if (S3DeleteRes.status == 200 && S3DeleteRes.data) {
                showSnackbar('File deleted', 'success')
            }
        }
        else {
            showSnackbar('something went wrong !', 'error')
        }
    }

    const onClose = () => {
        setEditItem(null)
        setCreateType('')
    }

    const IconLabel = ({ item }: { item: any }) => {
        let iconComponent;
        switch (getImageName(item)) {
            case fileType[0]:
                iconComponent = <i className="fi fi-rr-file-pdf" style={{ fontSize: '27px', lineHeight: 1, color: '#555' }}></i>;//<FaRegFilePdf style={style} color={colors?.normal} size={'28px'} />;
                break;
            case fileType[1]:
                iconComponent = <i className="fi fi-rr-file-video" style={{ fontSize: '27px', lineHeight: 1, color: '#555' }}></i> //<FaRegFileVideo style={style} color={colors?.normal} size={'30px'} />;
                break;
            case fileType[2]:
                iconComponent = <i className="fi fi-rr-file-word" style={{ fontSize: '27px', lineHeight: 1, color: '#555' }}></i> //<IoDocumentTextOutline style={style} color={colors?.normal} size={'30px'} />;
                break;
            case fileType[3]:
                iconComponent = <i className="fi fi-rr-file-image" style={{ fontSize: '27px', lineHeight: 1, color: '#555' }}></i> //<FaRegFileImage style={style} color={colors?.normal} size={'30px'} />;
                break;
            case fileType[4]:
                iconComponent = <i className="fi fi-rr-file-word" style={{ fontSize: '27px', lineHeight: 1, color: '#555' }}></i> // <FaRegFileWord style={style} color={colors?.normal} size={'30px'} />;
                break;
        }
        
        return (
            <div className='note-files-container'>
                <div onClick={()=>window.open(item.signedUrl ? item.signedUrl : item.uri, '_blank')} style={{width:'100%'}} className='note-files-sub-container'>
                    {iconComponent}
                    <div className='text-container'>
                        <span className='note-files-name'>{item?.name}</span>
                        <span className='time'>{getTime(item?.createdAt)}</span>
                    </div>
                </div>
                <div className='icon-Container'>
                    {isOwned &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isOwned &&
                                isLoading == item._id ? <CircularProgress size={'20px'} color="primary" /> :
                                <i className="fi fi-rr-trash" style={{ fontSize: '20px' }} onClick={() => setDeleteModal(item._id)} color={colors?.normal}></i>
                            }
                        </div>}
                    <DownloadFileComponent note={item} filename={item?.name} />
                </div>
            </div>
        )
    }


    return (
        <div className={`note-files-wrapper ${fullScreen? 'fullscreen' : ''}`}>
            {notes?.files?.length > 0 && isOwned &&
                <div className='create-button-wrapper'>
                    <CreateButton color={'#000000'} bgColor={'#EFEFEF'} onClick={onCreateNew}>
                        Add Files
                    </CreateButton>
                </div>}
                <div className={`note-files-items ${fullScreen? 'fullscreen' : ''}`}>
            {notes?.files?.map((item: any, index: number) => {
                return (
                    <div className='wrapper'>
                        <IconLabel item={item} />
                    </div>
                );
            })}
            </div>
            <CreateNoteModal
                open={createType == 'Note' || createType == 'Document'}
                editItem={editItem}
                addFile={true}
                detailRefresh={detailRefresh}
                createType={createType}
                modalStyle={{ padding: '0px' }}
                handleClose={onClose} />
            <CommonDeleteModal
                open={deleteModal}
                buttonLabel={'Yes, Delete'}
                description={`This action cannot be undone. Are you sure you want to proceed?`}
                title={'Delete File'}
                handleClose={() => setDeleteModal(null)}
                handleSubmit={() => onDeletFile(deleteModal)}
            />
            {/* {(notes?.files?.length == 0 || notes?.files == undefined) &&
                <NoNotes isOwned={isOwned} onClick={onCreateNew} type={type} />} */}
        </div>
    );
};

export default NoteFiles;
