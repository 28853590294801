import React, { useState } from 'react';
import GridContainer from '../../components/molecules/GridContainer';
import userSingle from '../../assets/images/userSingle.png'
import './index.scss'
import { formatName, loadUserFromLocalStorage } from '../../utils/commonUtils';
import {
    SettingsOutlined,
    Logout,
    DeleteOutline,
} from '@mui/icons-material';
import ListWrapper from '../../components/molecules/ListWrapper';
import CreateUser from '../Login/components/CreateUser';
import IconButton from '@mui/material/IconButton';
import { colors } from '../../assets/styles/colors'
import { CameraAltOutlined } from '@mui/icons-material';
import { useLogout, useUpdateUser } from 'utils/customHooks';
import ModalView from '../../components/atoms/ModalView';
import { useNavigate } from 'react-router-dom';
import LogOutModal from '../../components/atoms/LogOutModal';
import DeleteModal from '../../components/atoms/DeleteModal';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import CircularProgress from '@mui/material/CircularProgress';


const Account = () => {

    const logout = useLogout()
    const getUpdateUser = useUpdateUser()
    const user = loadUserFromLocalStorage();
    const [open, setOpen] = useState<boolean | string>(false);
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [openDeleteAccount, setOpenDeleteAccount] = useState<boolean | string>(false);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const settings = ['Change display name', 'Logout']

    const data = [
        { settings: settings },
    ]

    const getIcon = (item: string): JSX.Element => {
        const temp_Arr = settings
        switch (item) {
            case temp_Arr[0]:
                return <SettingsOutlined className='Drawer-icon' sx={{ fontSize: 20 }} />;
            case temp_Arr[1]:
                return <Logout className='Drawer-icon' sx={{ fontSize: 20 }} />;
            default:
                return <div />;
        }
    }

    const onClick = (setting: string) => {
        if (setting === 'Change display name')
            navigate('/change-name')
        else
            setOpen(setting)

    }

    const userNameModal = () => {
        return (
            <ModalView
                open={open === settings[0]}
                handleClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <CreateUser fromProfile={true} closeModal={handleClose} />
            </ModalView>
        )
    }

    async function updateProfilePic(imageUrl: string) {
        let params = {
            profilePic: imageUrl,
        };
        setLoading(true)
        const res = await fetchData(restAPIs.getUserUpdate('put', params),navigate)
        if (res.status == 200) {
            await getUpdateUser()
            setLoading(false)
            const S3DeleteRes = await fetchData(restAPIs.getS3Delete(user?.profilePic),navigate)
        }
        else {
            setLoading(false)
        }
    }

    const onUpdateImage = async (file: any) => {
        let formData = new FormData();
        formData.append('file', file);
        const res = await fetchData(restAPIs.getUserPicUpload(user?._id, file?.name, formData),navigate)
        if (res.status == 200) {
            updateProfilePic(`user/${user?._id}/${file?.name}`);
        }
    }

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target?.result) {
                    setSelectedImage(e.target.result as string);
                    onUpdateImage(file);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOpenLogout = () => {
        logout();
    }

    return (
        <GridContainer leftGridPartition={3 / 4} pageTitle={'Account Settings'} backButton>
            <div style={{ marginTop: '5px' }}>
                <div className='profile-card'>
                    <IconButton
                        style={{
                            position: 'relative',
                            backgroundColor: colors?.secondary,
                            left: 30,
                            top: 100,
                            height: '20px',
                            width: '20px',
                            color: 'white',
                            fontSize: '12px'
                        }}
                        size="small"
                        aria-label="close"
                        className='img-edit-button'
                        color="inherit"
                        onClick={() => document.getElementById?.('imageInput')?.click()}
                    >
                        <CameraAltOutlined sx={{ height: '.8rem' }} />
                    </IconButton>
                    {isLoading ? (
                        <div className="profile-pic-container">
                            <CircularProgress size={20} sx={{ color: colors?.primary }} />
                        </div>
                    ) :
                        <img src={user?.profilePic ? user?.profilePic : selectedImage || userSingle} className='user-single' alt="User Image" />}
                    <span className='name'>{formatName(user?.firstName, user?.lastName)}</span>
                    <span className='phone'>{user && user.email ? user.email
                        : user?.phone && user?.countryCode + user.phone}</span>
                    <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        hidden
                    />
                </div>
                <ListWrapper data={data} getIcon={getIcon} onClick={onClick} />
                <LogOutModal
                    open={open === settings[1]}
                    modalStyle={{ padding: '0px' }}
                    handleClose={handleClose}
                    handleOpen={handleOpenLogout}
                />
                {userNameModal()}
                <div className='__delete-account' onClick={() => setOpenDeleteAccount(true)}>
                    <DeleteOutline />
                    <p className='text'>Delete Account</p>
                </div>
            </div>
            <DeleteModal
                open={openDeleteAccount}
                modalStyle={{ padding: '0px' }}
                handleClose={() => setOpenDeleteAccount(false)}
            />
        </GridContainer>
    );
}

export default Account;