import React, { useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import './index.scss';
import NoNotes from '../NoNotes';
import ListActionMenu from '../ListActionMenu';
import { colors } from 'assets/styles/colors';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useFetchClassRoomItems } from 'utils/customHooks';
import _ from 'lodash';
import { useSnackbar } from '../SnackbarProvider';
import { useNavigate } from 'react-router-dom';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';

interface CurriculumFilesProps {
    data: any;
    type?: string;
    isOwned?: boolean;
    onEdit?: any
    moduleData?: any;
    selected?: number | null
}

const CurriculumFiles: React.FC<CurriculumFilesProps> = ({ data, onEdit, isOwned, type = 'Curriculum', moduleData, selected }) => {


    const { fetchCurriculum, fetchMetaData } = useFetchClassRoomItems()
    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar();
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onDelete = async () => {
        setIsLoading(true)
        const newSections = moduleData.sections.filter((_: any, idx: any) => idx !== selected);

        let params = {
            classRoomId: activeClassroom?._id,
            subjectId: moduleData.subjectId,
            sections: newSections,
        };
        const res = await fetchData(restAPIs.getCurriculumDelete(moduleData?._id, params),navigate)
        if (res.status == 200) {
            showSnackbar('Deleted successfully', 'success')
            fetchMetaData()
            fetchCurriculum(activeClassroom?._id)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
        }
    }

    if (!data) {
        return null
    }

    return (
        <div className='note-files-wrapper'>
            <div className='curriculum-files-wrapper'>
                <div className="title-note-container">
                    <Typography variant='body1'>
                        {data?.title}
                    </Typography>
                    {isLoading ?
                        <CircularProgress size={20} sx={{ color: colors?.primary }} /> :
                        <ListActionMenu enabled={isOwned} onEdit={onEdit} onDelete={onDelete} />}
                </div>
                <Typography variant='body2'>{data?.description}</Typography>
            </div>
            {(data?.length == 0 || data == undefined) &&
                <NoNotes type={type} />}
        </div>
    );
};

export default CurriculumFiles;
