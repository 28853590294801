import React, { useState } from "react";
import { TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import Alert from '@mui/material/Alert';
import PrimaryButton from "../../../components/atoms/CustomButtons";
import { useSelector, useDispatch } from 'react-redux';
import { setLoginForm, updateUserDetails } from '../../../redux/slices/setUser/userSlice'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

interface FormState {
  firstName: string;
  lastName: string;
}
interface FormErrorState {
  firstName: string;
  lastName: string;
  message: string | null
}

interface PhoneNumberLoginProps {
  closeModal?: () => void;
  setType?: React.Dispatch<React.SetStateAction<string | null>>
  fromProfile?: boolean
}

const initialForm = {
  firstName: '',
  lastName: '',
}

const initialError = {
  firstName: '',
  lastName: '',
  message: ''
}

const style = { height: '90vh' }

const CreateUser: React.FC<PhoneNumberLoginProps> = ({ setType, fromProfile, closeModal }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormState>(initialForm);
  const [errors, setErrors] = useState<FormErrorState>(initialError);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<string>('');
  const [data, setData] = useState({})
  const { loginForm } = useSelector((state: any) => state.user) || {};
  const { countryCode = '', phone = '' } = loginForm || {}
  const [open, setOpen] = React.useState(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = (): boolean => {
    let newErrors = { ...initialError };
    if (!formData.firstName) {
      newErrors.firstName = 'First Name is required';
    }
    if (!formData.lastName) {
      newErrors.lastName = 'Last Name is required';
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSignup = async () => {
    const userForm = fromProfile ? {
      firstName: formData?.firstName.trim(),
      lastName: formData?.lastName.trim()
    } : {
      phone: phone,
      countryCode: countryCode,
      userDetails: {
        firstName: formData?.firstName.trim(),
        lastName: formData?.lastName.trim()
      }
    }
    setIsLoading(true)
    const res = fromProfile ? await fetchData(restAPIs.putUserDetails(userForm),navigate) : await fetchData(restAPIs.getSignUp(userForm),navigate);
    setIsLoading(false)
    const response = res.data
    if (res.status === 200) {
      if (fromProfile) {
        closeModal?.()
        dispatch(updateUserDetails(response))
      }
      else {
        setType?.('otpScreen')
        dispatch(setLoginForm(userForm))
      }
    }
    else {
      if (fromProfile) {
        setOpen(true);
      }
      setErrors({ message: response?.message, ...response?.fields })
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      handleSignup()
    }
  };

  const goBack = () => {
    setType?.('phoneNumberLogin')
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div style={!fromProfile ? style : {}} className={'signup-form-wrapper'}>
      <FaChevronLeft onClick={goBack} className='phone-form-back-button' />
      {!fromProfile && errors?.message &&
        <Alert className="login-alert bottom" severity="error">{errors?.message}</Alert>}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errors?.message}
        action={action}
      />
      <form onSubmit={handleSubmit} className='login-form'>
        {!fromProfile &&
          <Typography style={{ marginBottom: '20px' }} variant='h1'>Create Account</Typography>}
        <TextField
          label="First Name"
          fullWidth
          variant="outlined"
          error={!!errors.firstName}
          helperText={errors.firstName}
          onChange={handleChange}
          margin="normal"
          name="firstName"
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
          }}
          className="input-field"
        />
        <TextField
          label="Last Name"
          name="lastName"
          fullWidth
          error={!!errors.lastName}
          helperText={errors.lastName}
          variant="outlined"
          onChange={handleChange}
          margin="normal"
          InputLabelProps={{
            style: { fontSize: '13px', fontWeight: 400, color: "#B4B4B4" },
          }}
          className="input-field"
        />

        <div style={{ marginTop: '20px' }}>
          <PrimaryButton
            fullWidth={false}
            isLoading={isLoading}>
              {fromProfile ? 'Change Name' : 'Sign up'}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}

export default CreateUser;
