import React, { useState, useEffect } from 'react';
import GridContainer from '../../components/molecules/GridContainer';
import { useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import './index.scss'
import SubjectsFiles from '../../components/atoms/SubjectsFiles';
import NoNotes from '../../components/atoms/NoNotes';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { CreateButton } from '../../components/atoms/CustomButtons';
import CreateSubjectModal from '../../components/atoms/CreateSubjectModal';
import ListActionMenu from '../../components/atoms/ListActionMenu';
import { colors } from 'assets/styles/colors';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useSnackbar } from '../../components/atoms/SnackbarProvider';
import { useNavigate } from 'react-router-dom';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import DeleteModal from 'components/atoms/DeleteModal';

const restrictModalData = { title: 'Access Restricted', desc: 'Sorry, only admins are allowed to add subjects in this classroom.', button: "Ok. got it" }

const Subjects: React.FC = () => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { fetchSubject, fetchMetaData } = useFetchClassRoomItems()
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const { isAdmin } = classroomData
    const activeClassroom = loadActiveClassroomLocalStorage()
    const { isSubjectLoading } = classroomData
    const [subjectList, setsSubjects] = useState(classroomData?.subjectList);
    const [createType, setCreateType] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<any>(null);
    const curriculum = classroomData.curriculum
    const [selected, setSelected] = useState<number>(0)
    const [isRestricted, setRestricted] = useState(false);

    const getSections = (id: any) => {
        let count = 0;
        if (curriculum?.length > 0) {
            curriculum.map((item: any) => {
                if (item.subjectId == id) {
                    count = item.sections.length;
                }
            });
        }
        return count;
    };

    const onDelete = async () => {
        setIsLoading(true)
        const res = await fetchData(restAPIs.getSubjectsDelete(subjectList?.[selected]?._id),navigate)
        if (res.status == 200) {
            showSnackbar('Subject deleted successfully', 'success')
            fetchSubject(activeClassroom?._id)
            fetchMetaData()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            showSnackbar('something went wrong !', 'error')
        }
    }
    const onEdit = () => {
        setEditItem(subjectList?.[selected])
        setCreateType('Subject')
    }

    const onCreateNew = () => {
        if(activeClassroom?.memberPermissions?.academic||isAdmin){
        setEditItem(null)
        setCreateType('Subject')
        }
        else setRestricted(true)
    }

    useEffect(() => {
        setsSubjects(classroomData?.subjectList)
    }, [classroomData?.subjectList]);

    return (
        <GridContainer
            pageTitle={'Subjects'}
            right={<SubjectsFiles subjects={subjectList?.[selected]}
                type='Subjects'
                getSections={getSections} />}
            leftGridPartition={subjectList?.length == 0 ? 1 : 1 / 2.5}>
            <div className='create-button-wrapper'>
                <CreateButton bgColor={'#575757'} onClick={onCreateNew}>
                    Create Subject
                </CreateButton>
                {isSubjectLoading &&
                <div className='loader-container'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div>}
            </div>
            {/* {isSubjectLoading &&
                <div style={{ marginBottom: '10px' }} className='loader-container'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div>} */}
            <div style={{ marginBottom: '20vh' }}>
                {subjectList?.map((item: any, index: number) => {
                    const data = [
                        `${item?.notesCount} ${item?.notesCount == 1 ? 'Note' : 'Notes'}`,
                        `${item?.documentsCount} ${item?.documentsCount == 1 ? 'Document' : 'Documents'} `,
                        `${getSections(item?._id)} ${getSections(item?._id) == 1 ? 'Session' : 'Sessions'}`]
                    return (
                        <div onClick={() => setSelected(index)} className={`notes-container ${selected == index ? 'selected' : ''}`}>

                            <div className="title-note-container">
                                <Typography fontWeight={500} fontSize={'.8rem'} variant='h4' key={index}>
                                    {item.name}
                                </Typography>
                                {(index === selected && isLoading) ?
                                    <CircularProgress size={20} sx={{ color: colors?.primary }} /> :
                                    <ListActionMenu item={item} onEdit={onEdit} onDelete={onDelete} />}
                            </div>
                            <div style={{ gap: '5px' }} className='notes-sub-container'>
                                {data?.map((items) => (
                                    <Typography fontSize={'10px'} className='subjects-count-name'>
                                        {items}
                                    </Typography>
                                ))}
                            </div>
                            <div className={`${selected == index ? 'dropdown-menu' : 'hide-dropdown-menu'}`} >
                                <SubjectsFiles subjects={subjectList[selected]}
                                    type='Subjects'
                                    getSections={getSections} />
                            </div>
                            <div className='line-separator' />
                        </div>
                    );
                })}
                <CreateSubjectModal
                    open={createType == 'Subject'}
                    modalStyle={{ padding: '0px' }}
                    editItem={editItem}
                    createType={createType}
                    handleClose={() => {setCreateType('')
                        setEditItem(null)
                    }} />
                <DeleteModal
                open={isRestricted}
                onSubmit={()=>setRestricted(false)}
                data={restrictModalData}
                modalStyle={{ padding: '0px' }}
                handleClose={() => setRestricted(false)}
            />
                {subjectList?.length == 0 &&
                    <NoNotes type={'Subjects'} />}
            </div>
        </GridContainer>
    )
};

export default Subjects;
