import React, { useEffect, useState } from 'react';
import ModalView from '../ModalView';
import { IoIosClose } from "react-icons/io";
import './index.scss'
import PrimaryButton from '../CustomButtons';
import { SESSION_TITLE } from 'utils/constants';
import { Typography } from '@mui/material';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useNavigate } from 'react-router-dom';
import { useFetchClassRoomItems } from 'utils/customHooks';
import _ from 'lodash';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
interface Props {
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    createType?: string;
    editItem?: any;
    subject?: any;
    module?: any;
    selected?: number | null
}
interface SubjectChange {
    target: {
        value: string;
    };
}
interface FileItem {
    file: File;
    type: string;
}

const CreateSubjectModal: React.FC<Props> = ({ open, handleClose, modalStyle, createType, editItem, subject, module, selected }) => {

    const navigate = useNavigate()
    const { setToogleDrawer } = useMetadataContext();
    const { fetchCurriculum, fetchMetaData } = useFetchClassRoomItems()
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target?.value?.length < SESSION_TITLE) {
            setName(event.target.value);
            setError('')
        }
        else {
            setError('Maximum length exceeds')
        }
    }

    useEffect(() => {
        if (editItem) {
            setName(editItem.title)
            setDescription(editItem.description)
        }
        else {
            setName('')
            setDescription('')
        }
    }, [editItem]);

    const onSuccess = () => {
        fetchMetaData()
        fetchCurriculum(activeClassroom?._id)
        handleClose?.()
        navigate('/curriculum')
        setToogleDrawer(false)
    }

    const onSubmit = async () => {
        if (name?.trim()?.length > 0) {
            setIsLoading(true)
            let temp_data = [
                {
                    title: name,
                    description: description?.length > 0 ? description.trim() : '',
                },
            ];
            let temp_sections = temp_data
            if (module?._id?.length) {
                let temp_module_session = [...module.sections];
                if (editItem && typeof selected === 'number') {
                    let sectionCopy = { ...temp_module_session[selected] };
                    sectionCopy.title = name;
                    sectionCopy.description = description?.length > 0 ? description.trim() : '';
                    temp_module_session[selected] = sectionCopy;
                    temp_sections = temp_module_session;
                } else {
                    temp_sections = [...temp_module_session, ...temp_data];
                }
            }

            let params = {
                classRoomId: activeClassroom?._id,
                sections: module?._id?.length > 0 ? temp_sections : temp_data,
                subjectId: subject?._id,
            };
            const res = module?._id?.length > 0 ? await fetchData(restAPIs.getupdateCurriculum(module?._id, params),navigate) : await fetchData(restAPIs.getAddCurriculum(params),navigate)
            setIsLoading(false)
            if (res.status === 200 && res.data) {
                onSuccess()
            }
        }
        else {
            setError('* required')
        }
    }

    const onClose = () => {
        handleClose?.()
        setName('')
        setError('')
        setDescription('')
    }

    const handleDescriptionChange = (event: SubjectChange) => {
        setDescription(event.target.value)
    };

    const RenderModal = () => (
        <div className="create-subject-modal">
            <div className='__header'>
                <h4>{editItem ? 'Edit' : 'Create'} {createType}</h4>
                <button onClick={onClose}><IoIosClose /></button>
            </div>
            <div className="file-upload-container">
                <div className="input-group">
                    <label>Title</label>
                    <input value={name} type="text" onChange={handleSubjectChange} placeholder={'Title'} />
                    <Typography sx={{ color: 'red' }} variant='subtitle2'>{error}</Typography>
                </div>
                <div className="input-group">
                    <label>Session details (optional)</label>
                    <textarea value={description} onChange={handleDescriptionChange} placeholder={'Enter session details'} />
                </div>
            </div>

            <div className='modal-footer-button'>
                <PrimaryButton
                    fullWidth={false}
                    onClick={onSubmit}
                    isLoading={isLoading}>
                    {editItem ? 'Update' : 'Create'} {createType}
                </PrimaryButton>
            </div>
        </div>
    )
    return (
        <ModalView open={open} handleClose={onClose} modalStyle={modalStyle}>
            {RenderModal()}
        </ModalView>
    )
};

export default CreateSubjectModal;
