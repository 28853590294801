import React from 'react';
import { ChevronRightOutlined } from '@mui/icons-material';
import './index.scss'
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';

interface ListWrapperProps {
    data?: any;
    getIcon?: (item: string) => JSX.Element;
    onClick?: any
}

const ListWrapper: React.FC<ListWrapperProps> = ({ data, getIcon, onClick }) => {
    const activeClassroom = loadActiveClassroomLocalStorage()

    return (
        <div className='__list-wrapper-container'>
            {data.map((item: any, index: number) => (!activeClassroom && index == 0) ? <div /> : (
                <div key={index}>
                    {item?.title && <h3>{item.title}</h3>}
                    <div className='__settings-items'>
                        {item.settings?.map((setting: string, j: number) => (
                            <div onClick={() => onClick(setting)} className='__single_item' key={j}>
                                <p className='text'>{setting}</p>
                                <ChevronRightOutlined className='Drawer-icon' sx={{ fontSize: 35 }} />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>

    );
}

export default ListWrapper;
