import React, { useState, useEffect } from 'react';
import GridContainer from '../../components/molecules/GridContainer';
import { useSelector } from 'react-redux';
import UserCard from '../../components/atoms/UserCard';
import { Typography } from '@mui/material';
import './index.scss'
import NoteFiles from '../../components/atoms/NoteFiles';
import NoNotes from '../../components/atoms/NoNotes';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { colors } from 'assets/styles/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { CreateButton } from '../../components/atoms/CustomButtons';
import CreateNoteModal from '../../components/atoms/CreateNoteModal';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import ListActionMenu from '../../components/atoms/ListActionMenu';
import { useSnackbar } from '../../components/atoms/SnackbarProvider';
import FilterList from '../../components/atoms/FilterList';
import ModalView from '../../components/atoms/ModalView';
import CommonDeleteModal from '../../components/atoms/CommonDeleteModal';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import DeleteModal from 'components/atoms/DeleteModal';

const restrictModalData = { title: 'Access Restricted', desc: 'Sorry, only admins are allowed to add documents in this classroom.', button: "Ok. got it" }

const Documents: React.FC = () => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const { isAdmin } = classroomData
    const activeClassroom = loadActiveClassroomLocalStorage()
    const { isLastDocument, isDocumentLoading } = classroomData
    const subjects = classroomData?.subjectList
    const [selected, setSelected] = useState<number>(0)
    const [documentList, setDocumentList] = useState<any>([]);
    const [createType, setCreateType] = useState<any>('');
    const { subject, refId } = useParams()
    const [editItem, setEditItem] = useState<any>(null);
    const [isLastPage, setIsLastPage] = useState<boolean>()
    const [page, setPage] = useState<number>(1)
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false)
    const { fetchDocuments, fetchMetaData } = useFetchClassRoomItems()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [selectedFilter, setSelectedFilter] = useState<any>(null)
    const [filter, setFilter] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [isRestricted, setRestricted] = useState(false);

    const loadMore = async (initialPage?: boolean) => {
        if (!isLastPage || initialPage) {
            let params = {
                id: selectedFilter ? selectedFilter?._id : activeClassroom?._id,
                type: selectedFilter ? 'subject' : 'classroom',
                offset: initialPage ? 0 : page,
            };
            setIsLoading(true)
            const res = refId ? await fetchData(restAPIs.getDocumentItem(refId), navigate) : await fetchData(restAPIs.getDocuments(params), navigate)
            setIsLoading(false)
            if (res?.status === 200 && res?.data) {
                if (refId) {
                    setDocumentList([res.data])
                }
                else {
                    setDocumentList(initialPage ? res.data?.document : documentList.concat(res.data?.document))
                }
                setIsLastPage(res.data.isLastPage)
                setPage(initialPage ? 1 : page + 1)
            }
        }
    }

    useEffect(() => {
        if (subject)
            setSelectedFilter({ _id: subject })
        else
            setSelectedFilter(null)
        if (refId) {
            setDocumentList([])
            loadMore(true)
        }
    }, [subject, refId]);

    const onDelete = async () => {
        setDeleteModal(false)
        setIsDeleteLoading(true)
        const res = await fetchData(restAPIs.getDocumentsDelete(documentList?.[selected]?._id), navigate)
        if (res.status == 200) {
            showSnackbar('Document deleted successfully', 'success')
            let temp = [...documentList]
            temp.splice(selected, 1)
            setDocumentList(temp)
            fetchDocuments()
            fetchMetaData()
            setIsDeleteLoading(false)
        }
        else {
            setIsDeleteLoading(false)
            showSnackbar('something went wrong !', 'error')
        }
    }
    const onEdit = () => {
        setEditItem(documentList[selected])
        setCreateType('Document')
    }

    const onCreateNew = () => {
        if(activeClassroom?.memberPermissions?.academic||isAdmin){
        setEditItem(null)
        setCreateType('Document')
        }
        else setRestricted(true)
    }

    const onFilterSet = () => {
        setFilter(false)
    }

    useEffect(() => {
        if (selectedFilter) {
            setPage(0)
            setDocumentList([])
            loadMore(true)
            setIsLastPage(false)
        }
        else if (!refId&&!subject) {
            setDocumentList(classroomData.documentList)

        }
    }, [selectedFilter]);

    useEffect(() => {
        setIsLastPage(isLastDocument)
    }, [isLastDocument])

    useEffect(() => {
        if (!subject && !refId){
            setDocumentList(classroomData.documentList)
            setIsLoading(false)
        }
    }, [classroomData.documentList]);

    const onBack = () => {
        navigate(-1)
    }

    return (
        <GridContainer
            pageTitle={!refId && 'Documents'}
            page={page}
            data={documentList}
            onBack={refId ? onBack : undefined}
            loading={isLoading}
            loadMore={loadMore}
            right={<NoteFiles notes={documentList[selected]} type='Documents' setNotes={setDocumentList} selected={selected} notesArray={documentList} />}
            leftGridPartition={1 / 2}>
            {!refId &&
                <div className='create-button-wrapper'>
                    <CreateButton bgColor={'#575757'} onClick={onCreateNew}>
                        Create Document
                    </CreateButton>
                    <div>
                        {(isLoading || isDocumentLoading) && <CircularProgress size={15} sx={{ color: colors?.primary, marginRight: '10px' }} />}
                        {selectedFilter ?subjects?.length>0&&
                            <i className="fi fi-rr-filter" style={{ color: colors?.primary, fontSize: '18px', cursor: 'pointer' }} onClick={() => setFilter(!filter)}></i>
                            :subjects?.length>0&&
                            <i className="fi fi-rr-filter" style={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => setFilter(!filter)}></i>}
                    </div>
                </div>}
            <ModalView title='Choose Subject' open={filter} handleClose={() => setFilter(false)} modalStyle={{ padding: 0 }}>
                <FilterList selected={selectedFilter} setSelected={setSelectedFilter} onFilterSet={onFilterSet} />
            </ModalView>
            {/* {(isLoading || isDocumentLoading) &&
                <div style={{ marginBottom: '10px' }} className={documentList?.length > 0?'loader-container':'loader-container_notes'}>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div>} */}
            <div style={{ marginBottom: '20vh' }}>
                {documentList?.map((item: any, index: number) => {
                    const fileCount = item?.files?.length
                    return (
                        <div onClick={() => setSelected(index)} className={`notes-container ${selected == index ? 'selected' : ''}`}>
                            <div className="title-note-container">
                                <span className='note-files-name'>{_.capitalize(item?.title)}</span>
                                {(index === selected && isDeleteLoading) ?
                                    <CircularProgress size={20} sx={{ color: colors?.primary }} /> :
                                    <ListActionMenu item={item} onEdit={onEdit} onDelete={() => setDeleteModal(true)} />}
                            </div>
                            <Typography variant='h6' fontWeight={400} style={{ wordWrap: 'break-word' }} fontSize={'12px'} key={index}>
                                {item.description}
                            </Typography>
                            <div className='notes-sub-container'>
                                {item?.subjectId?.name?.length > 0 &&
                                    <Tooltip title={item?.subjectId?.name}>
                                        <div className='notes-sub-name'>
                                            {item?.subjectId?.name}
                                        </div></Tooltip>}
                                <div className='notes-count-name'>
                                    {fileCount} {fileCount == 1 ? ' File' : ' Files'}
                                </div>
                            </div>

                            <div className="notes-user-card-wrapper">
                                <UserCard height={32} width={32} item={item} h1FontSize={'11px'} pFontSize={'10px'} />
                            </div>
                            <div className={`${selected == index ? 'dropdown-menu' : 'hide-dropdown-menu'}`} >
                                <NoteFiles notes={documentList[selected]} type='Documents' />
                            </div>
                            <div className={'line-separator'} />
                        </div>
                    );
                })}
                <CreateNoteModal
                    open={createType == 'Document'}
                    createType={createType}
                    editItem={editItem}
                    modalStyle={{ padding: '0px' }}
                    handleClose={() => setCreateType('')} />
                {isLoading && !isLastPage && documentList?.length > 0 &&
                    <div className='loader-container'>
                        <CircularProgress size={20} sx={{ color: colors?.primary }} />
                    </div>}
                {documentList?.length == 0 && !isLoading &&
                    <NoNotes type={'Documents'} />}
                <CommonDeleteModal
                    open={deleteModal}
                    buttonLabel={'Yes, Delete'}
                    description={`This action cannot be undone. Are you sure you want to proceed?`}
                    title={'Delete File'}
                    handleClose={() => setDeleteModal(false)}
                    handleSubmit={() => onDelete()}
                />
                <DeleteModal
                open={isRestricted}
                onSubmit={()=>setRestricted(false)}
                data={restrictModalData}
                modalStyle={{ padding: '0px' }}
                handleClose={() => setRestricted(false)}
            />
            </div>
        </GridContainer>
    )
};

export default Documents;
