import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './index.scss';
import { colors } from 'assets/styles/colors';
import { loadUserFromLocalStorage } from 'utils/commonUtils';

interface CustomMenuProps {
    onEdit: () => void;
    onDelete: () => void;
    item?: any;
    enabled?: boolean
}

const ListActionMenu: React.FC<CustomMenuProps> = ({ item, onEdit, onDelete, enabled = false }) => {
    const user = loadUserFromLocalStorage();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        onEdit();
        handleClose();
    };

    const handleDelete = () => {
        onDelete();
        handleClose();
    };
    if (!enabled) {
        if (user?._id !== item?.userId?._id && user?._id !== item?.createdBy?._id) {
            return null
        }
    }

    return (
        <div className="custom-menu">
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon style={{ color: '#575757' }} />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}

                className='___menu'
                PaperProps={{
                    sx: {
                        borderRadius: '10px',
                        boxShadow: 'none',
                        border: '1px solid #eee'
                    },
                }}
            >
                <MenuItem className='item-container' onClick={handleEdit}>
                    Edit
                </MenuItem>
                <MenuItem className='item-container' onClick={handleDelete}>
                    Delete
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ListActionMenu;

const IconStyle = {
    color: colors.tertiary,
    fontSize: '16px',
    marginRight: '10px'
}
