import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import './index.scss';
import GridContainer from '../../components/molecules/GridContainer';
import ClassRoomCard, { PreferenceCard } from '../../components/atoms/ClassRoomCard'
import PrimaryButton, { SecondryButton } from '../../components/atoms/CustomButtons';
import { useNavigate } from 'react-router-dom';
import { loadUserFromLocalStorage } from 'utils/commonUtils';
import { useSelector } from 'react-redux';

const preferenceData = [
    {
        title: 'Join Classroom',
        desc: 'Search with the classroom name or ID, find your classroom',
        route:'/findclassroom'
    },
]
const Home: React.FC = () => {
    const {user} = useSelector((state: any) => state.user)
    const data=user?.classRooms
    const navigate = useNavigate();

    return (
        <GridContainer pageTitle={'\t'} leftGridPartition={3/4}>
            <div className='home'>
                <div className="header-container">
                    <Typography fontWeight={700} fontSize={'27px'} variant="h1" className='home-form-title'>
                        Select your <Typography fontWeight={700} fontSize={'27px'} variant="h1">{data?.length > 0 ? 'Classroom' : 'Preference'}</Typography>
                    </Typography>

                    <PrimaryButton
                        fullWidth={false}
                        isLoading={false}
                        onClick={() => navigate('/findclassroom')}>
                        <div className="join-class-text">
                            Search Classroom
                        </div></PrimaryButton>
                </div>
                {data?.length == 0 &&
                    <PreferenceCard data={preferenceData} />}
                <ClassRoomCard data={data} />
            </div>
        </GridContainer>
    );
};

export default Home;
